import React, { useState, useEffect } from "react";
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    IconButton,
    Snackbar,
    Typography,
    Modal,
    Fade,
} from "@mui/material";
import { Link, Delete, Visibility, Edit } from "@mui/icons-material";
import { Outlet } from "react-router-dom";
import {
    deleteEmployee,
    getAwaitingData,
    getCompanies,
} from "../../../../utils/apiUtils";
import ErrorBoundary from "../../../../utils/ErrorBoundary";
import IndvContract from "./IndvContract";
import { decryptData } from "../../../../utils/cryptoUtils";
import InterviewModal from "./InterviewModal";
import { useAlert } from "../../../context/AlertContext";
import ContactSupportIcon from "@mui/icons-material/ContactSupport";
import Tooltip from "@mui/material/Tooltip";
import GradingIcon from "@mui/icons-material/Grading";

export default function Contracts() {
    const [awaiting, setAwaiting] = useState([]);
    const [modalData, setModalData] = useState(null);
    const [reload, setReload] = useState(false);
    const [errorAlert, setErrorAlert] = useState(false);
    const [message, setMessage] = useState("");
    const [open, setOpen] = useState(false);
    const [sites, setSites] = useState([]);
    const [openInterview, setOpenInterview] = useState(false);
    const { showAlert } = useAlert();
    const [token, setToken] = useState("");
    const [hrUser, setHrUser] = useState("");

    useEffect(() => {
        let token = localStorage.getItem("user");
        let getToken = JSON.parse(token);
        let final = getToken.token;
        setToken(final);
        setHrUser(getToken.user);

        async function fetchData() {
            try {
                const awaitingData = await getAwaitingData(final);
                setAwaiting(awaitingData);
            } catch (error) {
                console.error(error);
            }
        }
        fetchData();

        getCompanies(final)
            .then((data) => {
                setSites(data);
            })
            .catch((err) => [console.log(err)]);
    }, [reload]);
    const rel = () => {
        setReload(!reload);
    };

    const copyLink = (id) => {
        navigator.clipboard.writeText(id);
        showAlert("Link copied to clipboard", "success");
    };

    const handleOpen = (data) => {
        setModalData({ ...data });
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleDelete = async (id) => {
        try {
            await deleteEmployee(
                id,
                token,
                hrUser,
                "Removed From Contracts Page"
            );
            rel();
        } catch (err) {
            console.log(err);
        }
    };

    const handleOpenInterview = (data) => {
        setModalData({ ...data });
        setOpenInterview(true);
    };

    const handleCloseInterview = () => {
        setOpenInterview(false);
    };

    return (
        <main className="interview_calendar_main main">
            <h1 className="heading-master">Contracts To Approve</h1>
            {awaiting.length > 0 ? (
                <TableContainer
                    component={Paper}
                    sx={{ maxWidth: 1000, mb: 10 }}
                >
                    <Table className="contractStatTable">
                        <TableHead>
                            <TableRow>
                                <TableCell
                                    sx={{ fontWeight: "bold", color: "#fff" }}
                                >
                                    Name
                                </TableCell>
                                <TableCell
                                    sx={{ fontWeight: "bold", color: "#fff" }}
                                >
                                    Surname
                                </TableCell>
                                <TableCell
                                    sx={{ fontWeight: "bold", color: "#fff" }}
                                >
                                    Store
                                </TableCell>
                                <TableCell
                                    sx={{ fontWeight: "bold", color: "#fff" }}
                                >
                                    Contract Viewed
                                </TableCell>
                                <TableCell
                                    sx={{ fontWeight: "bold", color: "#fff" }}
                                >
                                    Link to Contract
                                </TableCell>
                                <TableCell
                                    sx={{ fontWeight: "bold", color: "#fff" }}
                                >
                                    View Interview
                                </TableCell>
                                <TableCell
                                    sx={{ fontWeight: "bold", color: "#fff" }}
                                >
                                    Edit
                                </TableCell>
                                <TableCell
                                    sx={{ fontWeight: "bold", color: "#fff" }}
                                >
                                    Delete
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {awaiting.map(function (applicant, idx) {
                                let name, surname, company, id, contractViewed;
                                if (!applicant._id) {
                                    name = decryptData(
                                        applicant.firstName,
                                        process.env.REACT_APP_EMP_KEY
                                    );
                                    surname = decryptData(
                                        applicant.lastName,
                                        process.env.REACT_APP_EMP_KEY
                                    );
                                    sites.forEach((site) => {
                                        if (
                                            site.companyId ==
                                            applicant.mainCompanyId
                                        )
                                            company = site.siteName;
                                    });
                                    id = applicant.employeeDetailsId;
                                    if (
                                        applicant.contractStatus ===
                                        "Contract Viewed"
                                    ) {
                                        contractViewed = "Yes";
                                    } else {
                                        contractViewed = "No";
                                    }
                                } else {
                                    name = applicant.firstName;
                                    surname = applicant.lastName;
                                    company = applicant.site;
                                    id = applicant._id;
                                    if (applicant.viewedContract) {
                                        contractViewed = "Yes";
                                    } else {
                                        contractViewed = "No";
                                    }
                                }
                                return (
                                    <TableRow
                                        key={idx}
                                        sx={{
                                            backgroundColor:
                                                applicant.hrApproved === false
                                                    ? "#ffeeee"
                                                    : null,
                                        }}
                                    >
                                        <TableCell>{name}</TableCell>
                                        <TableCell>{surname}</TableCell>
                                        <TableCell>{company}</TableCell>
                                        <TableCell>{contractViewed}</TableCell>
                                        <TableCell>
                                            <Tooltip title="Copy contract link">
                                                <IconButton
                                                    onClick={() =>
                                                        copyLink(
                                                            `https://onboarding.racetrackpitstop.co.uk/contract-confirmation?id=${id}`
                                                        )
                                                    }
                                                >
                                                    <Link />
                                                </IconButton>
                                            </Tooltip>
                                        </TableCell>
                                        <TableCell>
                                            <Tooltip title="View interview">
                                                <IconButton
                                                    onClick={() =>
                                                        handleOpenInterview(
                                                            applicant
                                                        )
                                                    }
                                                >
                                                    <Visibility />
                                                </IconButton>
                                            </Tooltip>
                                        </TableCell>
                                        <TableCell>
                                            <Tooltip title="View/approve">
                                                <IconButton
                                                    onClick={() =>
                                                        handleOpen(applicant)
                                                    }
                                                >
                                                    <GradingIcon />
                                                </IconButton>
                                            </Tooltip>
                                        </TableCell>
                                        <TableCell>
                                            {applicant._id ? (
                                                <Tooltip title="Contact IT to delete">
                                                    <ContactSupportIcon />
                                                </Tooltip>
                                            ) : (
                                                <Tooltip title="Delete applicant">
                                                    <IconButton
                                                        onClick={() =>
                                                            handleDelete(
                                                                applicant.employeeDetailsId
                                                            )
                                                        }
                                                    >
                                                        <Delete />
                                                    </IconButton>
                                                </Tooltip>
                                            )}
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            ) : (
                <Typography variant="h5">No contracts to be signed</Typography>
            )}
            <Snackbar open={errorAlert} message={message} />
            <ErrorBoundary>
                <Outlet />
            </ErrorBoundary>

            <Modal
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={open}>
                    <Paper>
                        <IndvContract
                            modalData={modalData}
                            openDialog={open}
                            close={handleClose}
                            rel={rel}
                        />
                    </Paper>
                </Fade>
            </Modal>
            <Modal
                open={openInterview}
                onClose={handleCloseInterview}
                closeAfterTransition
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={openInterview}>
                    <Paper>
                        <InterviewModal
                            modalData={modalData}
                            close={handleCloseInterview}
                        />
                    </Paper>
                </Fade>
            </Modal>
        </main>
    );
}
