import Box from "@mui/material/Box";
import { Button } from "@mui/material";
import axios from "axios";
import { useState } from "react";
import { Alert } from "@mui/material";

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
};
export default function DenyOvertime({ setOpenDeny, setReload, reload, id }) {
    //Alert
    const [alert, setAlert] = useState(false);
    const [disable, setDisable] = useState(false);
    const [message, setMessage] = useState("");
    const [severity, setSeverity] = useState("");

    const handleAlert = (message, severity, closeModal) => {
        setMessage(message);
        setSeverity(severity);
        setAlert(true);
        setTimeout(() => {
            setDisable(false);
            setAlert(false);
            setMessage("");
            setSeverity("");
            if (closeModal) {
                setOpenDeny(false);
                setReload(!reload);
            }
        }, 4000);
    };

    const handleDenyOvertime = () => {
        setDisable(true);
        axios
            .post(`${process.env.REACT_APP_API_URL}/notifications/overtime/update`, { id:id, status:false })
            .then(async (res) => {
                handleAlert("Successfully Denied", "success", true);
            })
            .catch((err) => {
                console.log(err);
                handleAlert("Did not submit - try again", "error", false);
            });
    };

    return (
        <Box sx={style} className="deny-overtime-modal">
            <h2>Deny Overtime</h2>
            <p>
                You are about to deny this overtime. The shift will revert back
                to its original. If you want to make a separate change to the
                shift afterwards, you must do this manually.
            </p>
            <section className="alert-section">
                {alert ? <Alert severity={severity}>{message}</Alert> : null}
            </section>
            <section className="btn-section">
                <Button
                    variant="contained"
                    color="success"
                    onClick={handleDenyOvertime}
                    disabled={disable}
                >
                    Deny
                </Button>
                <Button
                    variant="contained"
                    color="error"
                    onClick={() => setOpenDeny(false)}
                    disabled={disable}
                >
                    Close
                </Button>
            </section>
        </Box>
    );
}
