import { useState, useEffect } from "react";
import moment from "moment";
//MUI
import { Modal } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
//Components
import HolidayReportsInfo from "./HolidayReportsInfo";

export default function HolidayReportsRow({ holiday, header }) {
    const [openInfo, setOpenInfo] = useState(false);

    return (
        <>
            <tr>
                <td>{holiday.siteName}</td>
                <td>{`${holiday.firstName} ${holiday.lastName}`}</td>
                <td>{moment(holiday.startDate).format("DD/MM/YY")}</td>
                <td>{moment(holiday.endDate).format("DD/MM/YY")}</td>
                <td>{holiday.daysTaken}</td>
                <td>{holiday.currentStatus}</td>
                <td>
                    <InfoIcon
                        fontSize="large"
                        className="info-icon"
                        onClick={() => setOpenInfo(true)}
                    />
                </td>
            </tr>
            {/* Info Modal */}
            <Modal open={openInfo}>
                <HolidayReportsInfo
                    setOpenInfo={setOpenInfo}
                    holiday={holiday}
                    header={header}
                />
            </Modal>
        </>
    );
}
