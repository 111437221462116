import "./probations.scss";
import { useEffect, useState } from "react";
import axios from "axios";
import { useAlert } from "../../../context/AlertContext";
import { decryptData } from "../../../../utils/cryptoUtils";
import IndvProbation from "./IndvProbation";
import Loader from "../../../animations/Loader";

export default function Probations() {
    const [probations, setProbations] = useState([]);
    const url = process.env.REACT_APP_API_URL;
    const { showAlert } = useAlert();
    const [showLoader, setShowLoader] = useState(true);
    const [reload, setReload] = useState(false);

    useEffect(() => {
        let token = localStorage.getItem("user");
        let getToken = JSON.parse(token);
        let final = getToken.token;
        // let final = "test"
        const config = {
            headers: {
                "Content-Type": "application/json",
                "x-auth-token": final,
            },
        };

        // get all the data
        let localProbations = [];
        axios
            .get(`${url}/onBoard/probationDates`, config)
            .then((res) => {
                // console.log(res.data);
                for (let i = 0; i < res.data.length; i++) {
                    //adds the mongo ones directly to the array
                    if (res.data[i]._id) {
                        localProbations.push(res.data[i]);
                    } else {
                        localProbations.push({
                            firstName: decryptData(
                                res.data[i].firstName,
                                process.env.REACT_APP_EMP_KEY
                            ),
                            lastName: decryptData(
                                res.data[i].lastName,
                                process.env.REACT_APP_EMP_KEY
                            ),
                            site: res.data[i].companyName,
                            position: res.data[i].position,
                            startDate: res.data[i].startDate,
                            probationDate: res.data[i].probationDate,
                            id: res.data[i].employeeDetailsId,
                        });
                    }
                }
                setProbations(localProbations);
                setShowLoader(false);
            })
            .catch((err) => {
                console.log(err);
                showAlert("Something went wrong", "warning");
            });
    }, [reload]);

    const rel = () => {
        setReload(!reload);
    };

    return (
        <main className="probations_main">
            <section className="probations">
                <h1 className="heading-master">Probations</h1>
                {!showLoader ? (
                    <table>
                        <thead>
                            <tr>
                                <td>Name</td>
                                <td>Site</td>
                                <td>Position</td>
                                <td>Probation start</td>
                                <td>Probation end</td>
                                <td>Save</td>
                                <td>Delete</td>
                            </tr>
                        </thead>
                        <tbody>
                            {probations.map((probation, index) => {
                                return (
                                    <IndvProbation
                                        probation={probation}
                                        index={index}
                                        key={index}
                                        rel={rel}
                                    />
                                );
                            })}
                        </tbody>
                    </table>
                ) : (
                    <Loader />
                )}
            </section>
        </main>
    );
}
