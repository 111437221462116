import React from "react";
import { useNavigate, Outlet } from "react-router-dom";
import "./employee.scss";
import ErrorBoundary from "../../../utils/ErrorBoundary";

export default function EmployeeDash() {
    const navigate = useNavigate();

    return (
        <main className="main">
            <section className="home">
                <div
                    className="card"
                    onClick={() => navigate("/employee-management/employee")}
                >
                    <h1>Employees</h1>
                </div>
                <div
                    className="card"
                    onClick={() => navigate("/employee-management/rotas")}
                >
                    <h1>Rotas</h1>
                </div>
                <div
                    className="card"
                    onClick={() => navigate("/employee-management/dashboard")}
                >
                    <h1>Dashboard</h1>
                </div>
                <ErrorBoundary>
                    <Outlet />
                </ErrorBoundary>
            </section>
        </main>
    );
}
