import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
  } from "@mui/material";
  

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "60vw",
  maxHeight: "75vh",
  bgcolor: "background.paper",
  border: "none",
  boxShadow: 24,
  p: 4,
  overflowY: "scroll",
};

export default function NotesModal(props) {
    const notes = props.notes

  return (
    <Box sx={style}>
      <Typography id="modal-modal-title" variant="h6" component="h2" sx={{textAlign: "center"}}>
        Notes for discrepancy
      </Typography>
      <Typography id="modal-modal-description" sx={{ mt: 2 }}>
      <Table>
            <TableHead>
              <TableRow>
                <TableCell sx={{ fontWeight: "bold" }}>User</TableCell>
                <TableCell sx={{ fontWeight: "bold" }}>Note</TableCell>
                <TableCell sx={{ fontWeight: "bold" }}>Added on</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
                {notes.map((note, index) => {
                    return(
                        <TableRow key={index}>
                            <TableCell>{note.userId}</TableCell>
                            <TableCell>{note.note}</TableCell>
                            <TableCell>{new Date(note.dateTimeAdded).toLocaleDateString("en-GB")}</TableCell>
                        </TableRow>
                    )
                })}
            </TableBody>
        </Table>
      </Typography>
    </Box>
  );
}
