import { Button } from "@mui/material";
import Box from "@mui/material/Box";
import { useState, useEffect } from "react";
import axios from "axios";

const style = {
    width: "100%",
    maxheight: "10%",
    bgcolor: "background.paper",
    border: "none",
    boxShadow: 24,
    p: 4,
    maxHeight: "20vh",
    overflow: "auto",
    "@media(max-width: 690px)": {
        width: "90vw",
        maxHeight: "90vh",
    },
};

const NotesTab = ({
    shiftId,
    user,
    _notes,
    reload,
    error,
    baseUrl,
    config,
}) => {
    const [note, setNote] = useState("");
    const [buttonEnabled, setButtonEnabled] = useState(false);

    useEffect(() => {
        setNote("");
        setButtonEnabled(false);
    }, []);

    const handleNoteUpdate = async (_text) => {
        let str = _text.replace(/'/g, "");
        setNote(str);
    };

    const handleAddNote = async () => {
        if (note == "") {
            error("Please dont add an empty note", "warning");
        } else {
            setButtonEnabled(true);
            const data = {
                shiftId: shiftId,
                user: user,
                note: note,
            };

            await axios
                .post(`${baseUrl}/rota/shift/add-note`, data, config)
                .then((res) => {
                    error("Notes Successfully saved", "success");
                    reload();
                    setButtonEnabled(false);
                })
                .catch((err) => {
                    console.log(err);
                    error("Could not save note - Please try again", "error");
                    setButtonEnabled(false);
                });
        }
    };

    return (
        <Box style={style}>
            <div className="notes_header">
                <input
                    type="text"
                    placeholder="Enter Note..."
                    onChange={(e) => handleNoteUpdate(e.target.value)}
                    value={note}
                />

                <Button
                    variant="contained"
                    color="primary"
                    id="addNote-btn"
                    onClick={() => handleAddNote()}
                    disabled={buttonEnabled}
                >
                    Add Note
                </Button>
            </div>

            <div className="notes_scroll">
                {!!_notes ? (
                    <table
                        className="modal__tables notes_table"
                        style={{ marginTop: "15px" }}
                    >
                        <thead
                            style={{
                                backgroundColor: "#2c53a0",
                                color: "#fff",
                            }}
                        >
                            <tr>
                                <th style={{ width: "50%" }}>Note</th>
                                <th style={{ width: "30%" }}>User</th>
                                <th style={{ width: "20%" }}>Date / Time</th>
                            </tr>
                        </thead>
                        {_notes.map((note, i) => {
                            return (
                                <tr>
                                    <td>{note.note}</td>
                                    <td>{note.user}</td>
                                    <td>{note.dateAdded}</td>
                                </tr>
                            );
                        })}
                    </table>
                ) : (
                    <h2>NO NOTES TO DISPLAY</h2>
                )}
            </div>
        </Box>
    );
};

export default NotesTab;
