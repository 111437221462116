import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { useState } from "react";
import Stack from "@mui/material/Stack";


const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "40vw",
  maxHeight: "40vh",
  bgcolor: "background.paper",
  border: "none",
  borderRadius: "1rem",
  boxShadow: 24,
  p: 4,
  overFlowY: "scroll",
};

export default function LeaverConfirmModel({ close, employee, leaverDetails, handleConfirmLeaver }) {


  return (
    <Box sx={style}>
      <Typography
        id="modal-modal-title"
        variant="h6"
        component="h2"
        sx={{ textAlign: "center" }}
      >
        <h3>Leaver Confirmation</h3>
      </Typography>
      <Typography
        id="modal-modal-title"
        variant="h6"
        component="h2"
        sx={{ textAlign: "center", margin: "2% auto" }}
      >
        <p>Notice Date : {leaverDetails.dateLeft} </p>
        <p>Last Working Date : {leaverDetails.leavingDate} </p>
        <p>Leaver Reason : {leaverDetails.reason} </p>
      </Typography>

      <Typography
        id="modal-modal-title"
        variant="h6"
        component="h2"
        sx={{ textAlign: "center", margin: "2% auto" }}
      >
        <Stack
          direction="row"
          spacing={12}
          alignItems="center"
          justifyContent="center"
        >
          <Button onClick={close} variant="contained" color="error">
            Cancel
          </Button>
          <Button
            onClick={handleConfirmLeaver}
            variant="contained"
            color="success"
          >
            Remove Employee
          </Button>
        </Stack>
      </Typography>
    </Box>
  );
}
