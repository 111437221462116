import { useEffect, useState } from "react";
import PrintIndvShift from "./PrintIndvShift";

export default function PrintIndvDay({
    shifts,
    date,
    rotaId,
    employee,
    siteName,
    reloadData,
}) {
    //Sets Actual shifts list
    const [actualShifts, setActualShifts] = useState([]);

    useEffect(() => {
        let removedDuplicates = [];

        for (let s = 0; s < shifts.length; s++) {
            if (shifts[s].shiftId) {
                removedDuplicates.push(shifts[s]);
            } else {
                let duplicateCheck = removedDuplicates.findIndex(
                    (shift) => shift.leaveType === shifts[s].leaveType
                );

                if (duplicateCheck === -1) {
                    removedDuplicates.push(shifts[s]);
                }
            }
        }
        setActualShifts(removedDuplicates);
    }, []);

    return (
        <td>
            {actualShifts.map((shift, i) => {
                return (
                    <PrintIndvShift
                        key={i}
                        shift={shift}
                        employee={employee}
                        rotaId={rotaId}
                        siteName={siteName}
                        date={date}
                        reloadData={reloadData}
                    />
                );
            })}
        </td>
    );
}
