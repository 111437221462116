import { getCompanies } from "../../../../../utils/apiUtils";
import "../payroll.scss";
import { useEffect, useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import { IconButton, Alert } from "@mui/material";
import Loader from "../../../../animations/Loader";
import axios from "axios";
import { decryptData } from "../../../../../utils/cryptoUtils";
import IndvLetter from "./IndvLetter";

export default function LettersHome() {
    const url = "https://nitropos-backend.nitrosolutions.co.uk";
    const [token, setToken] = useState("");
    const [siteList, setSiteList] = useState([]);
    const [site, setSite] = useState("");
    const [currentSite, setCurrentSite] = useState("");
    const [month, setMonth] = useState("");
    const [letters, setLetters] = useState([]);
    const [search, setSearch] = useState(false);

    //alerts
    const [loader, setLoader] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [message, setMessage] = useState("");
    const [severity, setSeverity] = useState("");

    useEffect(() => {
        let user = localStorage.getItem("user");
        let parseU = JSON.parse(user);
        let localToken = parseU.token;
        setToken(localToken);

        getCompanies(localToken)
            .then((sites) => {
                setSiteList(sites);
            })
            .catch((err) => {
                console.log(`Could not get sites: ${err}`);
            });
    }, []);

    const rel = () => {
        handleMonth();
    };

    const handleMonth = () => {
        setCurrentSite(site);
        setLetters([]);
        if (
            site === "" ||
            site === "--Please select a site--" ||
            month === ""
        ) {
            setMessage(`Site and month has to be selected, try again`);
            setSeverity("warning");
            setShowAlert(true);
            setTimeout(() => {
                setShowAlert(false);
            }, 3500);
        } else if (loader === true) {
            setSearch(true);
            setTimeout(() => {
                setSearch(false);
            }, 3000);
        } else {
            setLoader(true);
            try {
                let data = {
                    date: month,
                    site: site,
                    token: token,
                };
                axios
                    .post(`${url}/discrepancies/letterDetails`, data)
                    .then(async (res) => {
                        console.log(res);
                        setLoader(false);
                        const monthDiscreps = res.data;
                        if (monthDiscreps.length === 0) {
                            setMessage(`No discrepancies to show`);
                            setSeverity("success");
                            setShowAlert(true);
                            setTimeout(() => {
                                setShowAlert(false);
                            }, 3500);
                        } else {
                            for (let d = 0; d < monthDiscreps.length; d++) {
                                let _name = decryptData(
                                    monthDiscreps[d].name,
                                    process.env.REACT_APP_EMP_KEY
                                );
                                setLetters((discrepancies) => [
                                    ...discrepancies,
                                    {
                                        eposStaffId:
                                            monthDiscreps[d].eposStaffId,
                                        mainCompanyId:
                                            monthDiscreps[d].mainCompanyId,
                                        name: _name
                                            ? _name
                                            : "Can not decrypt name",
                                        sum: monthDiscreps[d].totalOwed,
                                        discrepancies:
                                            monthDiscreps[d].discrepancies,
                                        letters: monthDiscreps[d].letters,
                                    },
                                ]);
                            }
                        }
                    })
                    .catch((err) => {
                        console.log(`Could not get discrepancies: ${err}`);
                    });
            } catch (err) {
                setLoader(false);
                console.log(`Could not get letters: ${err}`);
                setMessage(`Could not get letters: ${err}`);
                setSeverity("error");
                showAlert(true);
                setTimeout(() => {
                    setShowAlert(false);
                }, 3500);
            }
        }
    };

    const handleKeyDown = (e) => {
        if (e.keyCode === 13) {
            handleMonth();
        }
    };

    return (
        <main className="discrepancies_main main">
            <h1 className="heading-master">Discrepancy letters</h1>

            <section className="row_select">
                <select onChange={(e) => setSite(e.target.value)}>
                    <option>--Please select a site--</option>
                    {siteList.map((site) => {
                        return (
                            <option key={site.companyId} value={site.eposId}>
                                {site.siteName}
                            </option>
                        );
                    })}
                </select>
                <input
                    type="month"
                    min="2023-01"
                    onChange={(e) => setMonth(e.target.value)}
                    onKeyDown={handleKeyDown}
                />
                <IconButton onClick={handleMonth} disabled={search}>
                    <SearchIcon color="primary" fontSize="large" />
                </IconButton>
            </section>
            {showAlert ? (
                <Alert severity={severity} variant="filled">
                    {message}
                </Alert>
            ) : loader ? (
                <Loader />
            ) : null}
            {letters.length > 0 ? (
                <section className="letters">
                    <h3>Discrepancy letters to be sent for: {currentSite}</h3>
                    <table>
                        <thead>
                            <tr>
                                <th>Employee</th>
                                <th>Total Discrepancies</th>
                                <th>Discrepancy letter</th>
                                <th>Letter of concern</th>
                            </tr>
                        </thead>
                        <tbody>
                            {letters.map((discrepancy) => {
                                return (
                                    <IndvLetter
                                        discrepancy={discrepancy}
                                        rel={rel}
                                        month={month}
                                    />
                                );
                            })}
                        </tbody>
                    </table>
                </section>
            ) : null}
        </main>
    );
}
