import { useState, useEffect } from "react";
import axios from "axios";
import "./admin.scss";
import IndvPayRate from "./IndvPayRate";
import { Button, Alert, Modal, Box, Stack, Typography } from "@mui/material";
import { getPayRates } from "../../../utils/apiUtils";

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "30vw",
    bgcolor: "background.paper",
    border: "none",
    borderRadius: "1rem",
    boxShadow: 24,
    p: 4,
    "@media(max-width: 600px)": {
        width: "90vw",
        maxHeight: "40vh",
    },
    "@media(min-width: 601px) and (max-width: 900px)": {
        width: "45vw",
        maxHeight: "40vh",
    },
};

export default function PayRates() {
    const [rates, setRates] = useState([]);
    const [user, setUser] = useState("");
    const [reload, setReload] = useState(false);
    const [isDirty, setIsDirty] = useState(false);
    const [ogRates, setOgRates] = useState([]);
    const [label, setLabel] = useState("");
    const [newRate, setNewRate] = useState("");
    const [showClashes, setShowClashes] = useState(false);
    const [token, setToken] = useState("");
    const config = {
        headers: { "Content-Type": "application/json", "x-auth-token": token },
    };

    //alerts
    const [showAlert, setShowAlert] = useState(false);
    const [message, setMessage] = useState("");
    const [severity, setSeverity] = useState("");

    useEffect(() => {
        let checkId = localStorage.getItem("user");
        let parsed = JSON.parse(checkId);
        let token = parsed.token;
        setUser(parsed.user);
        setToken(token);
        //gets current pay rates
        getPayRates(token)
            .then((data) => {
                setRates(data);
                setOgRates(data);
            })
            .catch((err) => {
                console.log(err);
            });
    }, [reload]);

    //checks for a negative rate, and changes the array
    const handleRateChange = async (newRate, index) => {
        var currRate = ogRates[index].rate;
        if (parseInt(newRate) < 0) {
            document.getElementById("rate_btn").style.display = "none";
            setSeverity("warning");
            setMessage("Pay rates cannot be negative");
            setShowAlert(true);
            setTimeout(() => {
                setShowAlert(false);
                setSeverity("");
                setMessage("");
                document.getElementById("rate_btn").style.display = "flex";
            }, 3000);
        } else {
            var affected = [];
            await axios
                .get(
                    `${process.env.REACT_APP_API_URL}/rates/update/pre-check?rate=${currRate}`,
                    config
                )
                .then((resp) => {
                    affected = resp.data;
                })
                .catch((err) => {
                    console.log(err);
                });
            let adjustState = [...rates];
            adjustState[index] = {
                ...adjustState[index],
                rate: newRate,
                affected: affected,
            };
            setRates(adjustState);
            setIsDirty(true);
        }
    };

    const changeRates = async (isForce) => {
        document.getElementById("rate_btn").style.display = "none";
        let data = {
            user: user,
            rates: rates,
        };
        const affected = rates.filter((r) => r.affected && r.affected > 0);
        if (isForce || affected.length == 0) {
            console.log(
                `IS FORCED AND ${affected.length} RATES BEING FORCE CHANGED`
            );
            await axios
                .post(
                    `${process.env.REACT_APP_API_URL}/rates/update`,
                    data,
                    config
                )
                .then((res) => {
                    console.log(res.data);
                    setSeverity("success");
                    setMessage("Pay rates have been updated");
                    setShowAlert(true);
                    setTimeout(() => {
                        setShowAlert(false);
                        setSeverity("");
                        setMessage("");
                        document.getElementById("rate_btn").style.display =
                            "flex";
                        setIsDirty(!isDirty);
                        setReload(!reload);
                    }, 3000);
                })
                .catch((err) => {
                    console.log(err);
                    setSeverity("error");
                    setMessage("Something went wrong, please try again");
                    setShowAlert(true);
                    setTimeout(() => {
                        setShowAlert(false);
                        setSeverity("");
                        setMessage("");
                        document.getElementById("rate_btn").style.display =
                            "flex";
                    }, 3000);
                });
        } else {
            console.log("DB CLASH");
            setShowClashes(true);
        }
    };

    const addRate = async () => {
        var idx = rates.findIndex(
            (r) =>
                parseFloat(r.rate).toFixed(2) == parseFloat(newRate).toFixed(2)
        );
        if (idx >= 0) {
            setSeverity("error");
            setMessage("This rate already exists, please try again");
            setShowAlert(true);
            setTimeout(() => {
                setShowAlert(false);
                setSeverity("");
                setMessage("");
                document.getElementById("rate_btn").style.display = "flex";
            }, 3000);
        } else if (idx < 0 && newRate !== "") {
            let data = {
                rate: parseFloat(newRate).toFixed(2),
                label: label,
                active: true,
            };
            axios
                .post(
                    `${process.env.REACT_APP_API_URL}/rates/add`,
                    data,
                    config
                )
                .then((resp) => {
                    setLabel("");
                    setNewRate("");
                    setSeverity("success");
                    setMessage("Pay rate has been added");
                    setShowAlert(true);
                    setTimeout(() => {
                        setShowAlert(false);
                        setSeverity("");
                        setMessage("");
                        setReload(!reload);
                    }, 3000);
                })
                .catch((err) => {
                    console.log(err);
                    setSeverity("error");
                    setMessage("Something went wrong, please try again");
                    setShowAlert(true);
                    setTimeout(() => {
                        setShowAlert(false);
                        setSeverity("");
                        setMessage("");
                        document.getElementById("rate_btn").style.display =
                            "flex";
                    }, 3000);
                });
        } else {
            setSeverity("error");
            setMessage("Cannot submit an empty rate, please try again");
            setShowAlert(true);
            setTimeout(() => {
                setShowAlert(false);
                setSeverity("");
                setMessage("");
                document.getElementById("rate_btn").style.display = "flex";
            }, 3000);
        }
    };

    const reset = async () => {
        document.getElementById("rate_btn").style.display = "flex";
        setShowClashes(false);
    };

    const deleteRate = (index) => {
        let adjustState = [...rates];
        var deleted = false;
        if (!rates[index].deleted || rates[index].deleted == false) {
            deleted = true;
        }
        adjustState[index] = { ...adjustState[index], deleted: deleted };
        setRates(adjustState);
        setIsDirty(true);
    };

    return (
        <main className="main">
            <h1 className="heading-master">Pay Rates</h1>

            <div style={{ display: "flex", minHeight: "78vh" }}>
                <section
                    className="pay__rates"
                    style={{
                        width: "20%",
                        height: "25vh",
                        marginRight: "-10%",
                    }}
                >
                    <h3>Add New Rate</h3>
                    <input
                        style={{
                            border: "1px solid black",
                            borderRadius: "10px",
                            padding: "5px",
                            fontSize: "1.3rem",
                        }}
                        type="text"
                        placeholder="Optional Label..."
                        onChange={(e) => setLabel(e.target.value)}
                        value={label}
                    />
                    <br />
                    <input
                        style={{
                            border: "1px solid black",
                            borderRadius: "10px",
                            padding: "5px",
                            fontSize: "1.3rem",
                        }}
                        type="number"
                        min={0}
                        placeholder="Enter Rate..."
                        onChange={(e) => setNewRate(e.target.value)}
                        value={newRate}
                    />
                    <br />
                    <Button
                        variant="contained"
                        color="success"
                        onClick={() => addRate()}
                    >
                        Add
                    </Button>
                </section>

                <section className="pay__rates" style={{ width: "50%" }}>
                    <table>
                        <thead>
                            <tr>
                                <th>Age</th>
                                <th>Current rate</th>
                                <th>New rate</th>
                                <th>Remove</th>
                            </tr>
                        </thead>
                        <tbody>
                            {rates.map(function (rate, index) {
                                return (
                                    <IndvPayRate
                                        key={index}
                                        rate={rate}
                                        index={index}
                                        handleChange={handleRateChange}
                                        deleteRate={deleteRate}
                                    />
                                );
                            })}
                        </tbody>
                    </table>
                    <Button
                        variant="contained"
                        color="success"
                        sx={{ marginBottom: "2%" }}
                        id="rate_btn"
                        onClick={() => changeRates()}
                        disabled={!isDirty}
                    >
                        Update
                    </Button>
                </section>
            </div>

            <Modal
                open={showClashes}
                aria-labelledby="stock-addition"
                aria-describedby="add-stock-to-product"
            >
                <Box sx={style}>
                    <div id="payrollTable">
                        <Typography
                            id="stock-addition"
                            variant="h6"
                            component="h2"
                        >
                            The following rate updates will have an impact on
                            employees -
                        </Typography>
                        <table>
                            <thead>
                                <tr>
                                    <th>Current Rate</th>
                                    <th>New Rate</th>
                                    <th>Employees Affected</th>
                                </tr>
                            </thead>
                            <tbody>
                                {rates.map(function (d, i) {
                                    return (
                                        <>
                                            {d.affected ? (
                                                <tr>
                                                    <td>{ogRates[i].rate}</td>
                                                    <td>{d.rate}</td>
                                                    <td>{d.affected}</td>
                                                </tr>
                                            ) : null}
                                        </>
                                    );
                                })}
                            </tbody>
                        </table>
                    </div>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        <Stack
                            spacing={12}
                            direction="row"
                            sx={{ width: "70%", margin: "auto" }}
                        >
                            <Button
                                variant="contained"
                                color="error"
                                onClick={() => {
                                    reset();
                                }}
                            >
                                BACK
                            </Button>
                            <Button
                                variant="contained"
                                color="success"
                                onClick={() => {
                                    changeRates(true);
                                }}
                            >
                                CONFIRM
                            </Button>
                        </Stack>
                    </Typography>
                </Box>
            </Modal>

            {showAlert ? (
                <Alert severity={severity} sx={{ marginBottom: "2%" }}>
                    {message}
                </Alert>
            ) : null}
        </main>
    );
}
