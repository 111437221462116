import "./header.scss";
import logo from "../logo/Logo.png";
import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import React, { useState, useEffect } from "react";
import LogoutButton from "./../logout/LogoutButton";
import axios from "axios";
import IdleTimeOut from "./IdleTimeOut";
import { Box, Alert } from "@mui/material";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { getAwaitingData } from "../../utils/apiUtils";
import { io } from "socket.io-client";
import { decryptData } from "../../utils/cryptoUtils";
import { ControlPointSharp } from "@mui/icons-material";

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "none",
    borderRadius: "1rem",
    boxShadow: 24,
    p: 4,
};

export default function Header({ loggedIn, setLoggedIn }) {
    const navigate = useNavigate();
    const [isActive, setIsActive] = useState(true);
    const [contracts, setContracts] = useState(0);
    const [bankChanges, setBankChanges] = useState(0);
    const [showAlert, setShowAlert] = useState(false);
    const [alerts, setAlerts] = useState([]);
    const [open, setOpen] = useState(false);
    // const socket = io(`${process.env.REACT_APP_API_URL}`);
    let idleTimeout = 30000;
    let IntId;
    //Menu Openers
    const [adminMenuAnchorEl, setAdminMenuAnchorEl] = useState(null);
    const [onboardingMenuAnchorEl, setOnboardingMenuAnchorEl] = useState(null);
    const [employeeManagementMenuAnchorEl, setEmployeeManagementMenuAnchorEl] =
        useState(null);
    const [reportingMenuAnchorEl, setReportingMenuAnchorEl] = useState(null);

    const handleOpen = () => {
        setIsActive(true);
        setOpen(true);
        if (isActive) {
            IntId = setInterval(() => {
                // console.log(idleTimeout);
                if (idleTimeout > 0) {
                    idleTimeout = idleTimeout - 1000;
                } else {
                    window.location.replace("/");
                    localStorage.clear();
                }
            }, 1000);
        }
    };
    const handleClose = () => {
        setOpen(false);
        setIsActive(false);
        idleTimeout = 30000;
        clearInterval(IntId);
    };

    useEffect(() => {
        let userD = localStorage.getItem("user");
        let user = JSON.parse(userD);
        fetchData(user.token);
        let localConfig = {
            headers: {
                "Content-Type": "application/json",
                "x-auth-token": user.token,
            },
        };
        // axios
        //   .get(`${process.env.REACT_APP_API_URL}/banking/pending`, localConfig)
        //   .then((res) => {
        //     setBankChanges(res.data.length);
        //   })
        //   .catch((err) => {
        //     console.log(`Could not get pending banking changes: ${err}`);
        //   });

        // alerts for docs
        // let url = "http://10.0.0.34:4030"
        // socket.connect();

        // socket.on(`newAlert`, (data) => {
        //   console.log(`socket received`)
        //   let firstName = decryptData(data.firstName, process.env.REACT_APP_EMP_KEY)
        //   let lastName = decryptData(data.lastName, process.env.REACT_APP_EMP_KEY)
        //   let message = `New document (${data.document}) uploaded for employee: ${firstName} ${lastName} for site: ${data.companyName}`
        //   setAlerts((alerts) => [...alerts, { message: message }]);
        //   setShowAlert(true);

        // });
        // return () => {
        //   socket.disconnect();
        // };
    }, []);

    const fetchData = async (token) => {
        await getAwaitingData(token)
            .then((data) => {
                let contracts = 0;
                data.forEach((contract) => {
                    if (contract.hrApproved === false) {
                        contracts = contracts + 1;
                    }
                });
                setContracts(contracts);
            })
            .catch((err) => {
                console.log(`could not get details: ${err}`);
            });
    };

    // === Menu Functions Start ===
    const handleAdminMenuClick = (event) => {
        setAdminMenuAnchorEl(event.currentTarget);
    };

    const handleOnboardingMenuClick = (event) => {
        setOnboardingMenuAnchorEl(event.currentTarget);
    };

    const handleEmployeeManagementMenuClick = (event) => {
        setEmployeeManagementMenuAnchorEl(event.currentTarget);
    };

    const handleReportingMenuClick = (event) => {
        setReportingMenuAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAdminMenuAnchorEl(null);
        setOnboardingMenuAnchorEl(null);
        setEmployeeManagementMenuAnchorEl(null);
        setReportingMenuAnchorEl(null);
    };
    // === Menu Functions Start ===
    return (
        <header>
            {alerts.length > 1 ? (
                <>
                    {alerts.map((alert) => {
                        return (
                            <>
                                {showAlert ? (
                                    <Alert
                                        severity="info"
                                        variant="filled"
                                        className="alert"
                                        onClose={() => setShowAlert(false)}
                                    >
                                        {alert.message}
                                    </Alert>
                                ) : null}
                            </>
                        );
                    })}
                </>
            ) : null}
            <img
                src={logo}
                id="hr-logo"
                alt="Nitro Solutions Logo"
                width="18%"
                onClick={function () {
                    navigate("/home");
                }}
            />
            <nav>
                {/* ADMIN TASKS */}
                <Button
                    aria-controls="admin-menu"
                    aria-haspopup="true"
                    onClick={handleAdminMenuClick}
                    sx={{ color: "#2c53a0", fontSize: "1.1rem" }}
                >
                    Admin tasks
                </Button>
                <Menu
                    id="admin-menu"
                    anchorEl={adminMenuAnchorEl}
                    open={Boolean(adminMenuAnchorEl)}
                    onClose={handleMenuClose}
                    MenuListProps={{
                        "aria-labelledby": "admin-menu",
                    }}
                    onClick={handleMenuClose}
                >
                    <MenuItem onClick={() => navigate("/admin/discrepancies")}>
                        Discrepancies
                    </MenuItem>
                    <MenuItem
                        onClick={() => navigate("/admin/discrepancy-letters")}
                    >
                        Discrepancy Letters
                    </MenuItem>
                    <MenuItem onClick={() => navigate("/admin/staff-banking")}>
                        Staff Banking
                        {bankChanges > 0 ? (
                            <span id="contract_alert">{bankChanges}</span>
                        ) : null}
                    </MenuItem>
                    <MenuItem onClick={() => navigate("/admin/update-company")}>
                        Update Company
                    </MenuItem>
                    <MenuItem onClick={() => navigate("/admin/create-company")}>
                        Create Company
                    </MenuItem>
                    <MenuItem onClick={() => navigate("/admin/pay-rates")}>
                        Pay Rates
                    </MenuItem>
                    <MenuItem onClick={() => navigate("/admin/roles")}>
                        Create Roles
                    </MenuItem>
                    <MenuItem onClick={() => navigate("/admin/holiday-groups")}>
                        Holiday Groups
                    </MenuItem>
                    <MenuItem onClick={() => navigate("/admin/scripts")}>
                        Scripts
                    </MenuItem>
                    <MenuItem
                        onClick={() => navigate("/admin/overtime-approval")}
                    >
                        Overtime Approval
                    </MenuItem>
                </Menu>
                {/* END ADMIN TASKS */}
                {/* ONBOARDING */}
                <Button
                    aria-controls="onboarding-menu"
                    aria-haspopup="true"
                    onClick={handleOnboardingMenuClick}
                    sx={{ color: "#2c53a0", fontSize: "1.1rem" }}
                >
                    Onboarding
                </Button>
                <Menu
                    id="onboarding-menu"
                    anchorEl={onboardingMenuAnchorEl}
                    open={Boolean(onboardingMenuAnchorEl)}
                    onClose={handleMenuClose}
                    MenuListProps={{
                        "aria-labelledby": "onboarding-menu",
                    }}
                    onClick={handleMenuClose}
                >
                    <MenuItem onClick={() => navigate("/interview-calendar")}>
                        Interview Calendar
                    </MenuItem>
                    <MenuItem
                        onClick={() => navigate("/interview-calendar/schedule")}
                    >
                        Interview Schedule
                    </MenuItem>
                    <MenuItem onClick={() => navigate("/onboarding/status")}>
                        Onboarding Status
                    </MenuItem>
                    <MenuItem onClick={() => navigate("/contracts")}>
                        Contracts to Approve{" "}
                        {contracts > 0 ? (
                            <span id="contract_alert">{contracts}</span>
                        ) : null}
                    </MenuItem>
                    <MenuItem
                        onClick={() => navigate("/onboarding/new-manager")}
                    >
                        New Manager
                    </MenuItem>
                    <MenuItem
                        onClick={() => navigate("/onboarding/manual-contract")}
                    >
                        NSP Generation
                    </MenuItem>
                </Menu>
                {/* END ONBOARDING */}
                {/* EMPLOYEE MANAGEMENT */}
                <Button
                    aria-controls="employee-management-menu"
                    aria-haspopup="true"
                    onClick={handleEmployeeManagementMenuClick}
                    sx={{ color: "#2c53a0", fontSize: "1.1rem" }}
                >
                    Employee Management
                </Button>
                <Menu
                    id="employee-management-menu"
                    anchorEl={employeeManagementMenuAnchorEl}
                    open={Boolean(employeeManagementMenuAnchorEl)}
                    onClose={handleMenuClose}
                    MenuListProps={{
                        "aria-labelledby": "employee-management-menu",
                    }}
                    onClick={handleMenuClose}
                >
                    <MenuItem
                        onClick={() =>
                            navigate("/employee-management/employee")
                        }
                    >
                        Employees
                    </MenuItem>
                    <MenuItem
                        onClick={() =>
                            navigate("/employee-management/archived-employees")
                        }
                    >
                        Archived Employees
                    </MenuItem>
                    <MenuItem
                        onClick={() =>
                            navigate("/employee-management/holiday-calendar")
                        }
                    >
                        Holiday Calendar
                    </MenuItem>
                    <MenuItem
                        onClick={() =>
                            navigate("/employee-management/manual-leave")
                        }
                    >
                        Manual Leave
                    </MenuItem>
                    <MenuItem
                        onClick={() => navigate("/employee-management/rotas")}
                    >
                        Rotas
                    </MenuItem>
                    <MenuItem
                        onClick={() => navigate("/employee-management/leavers")}
                    >
                        Leavers
                    </MenuItem>
                    <MenuItem
                        onClick={() =>
                            navigate("/employee-management/probations")
                        }
                    >
                        Probations
                    </MenuItem>
                    <MenuItem
                        onClick={() => navigate("/employee-management/payroll")}
                    >
                        Payroll
                    </MenuItem>
                </Menu>{" "}
                {/* END EMPLOYEE MANAGEMENT */}
                {/* REPORTING */}
                <Button
                    aria-controls="employee-management-menu"
                    aria-haspopup="true"
                    onClick={handleReportingMenuClick}
                    sx={{ color: "#2c53a0", fontSize: "1.1rem" }}
                >
                    Reporting
                </Button>
                <Menu
                    id="reporting-menu"
                    anchorEl={reportingMenuAnchorEl}
                    open={Boolean(reportingMenuAnchorEl)}
                    onClose={handleMenuClose}
                    MenuListProps={{
                        "aria-labelledby": "reporting-menu",
                    }}
                    onClick={handleMenuClose}
                >
                    <MenuItem
                        onClick={() => navigate("/reporting/holiday-reports")}
                    >
                        Holiday Reports
                    </MenuItem>
                </Menu>
                {/* END REPORTING */}
                <LogoutButton setLoggedIn={setLoggedIn} />
            </nav>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography
                        id="modal-modal-title"
                        variant="h6"
                        component="h2"
                    >
                        Your session will close soon, interact with your screen
                        to stay logged in
                    </Typography>
                </Box>
            </Modal>
        </header>
    );
    return (
        <header>
            {/* AUTO LOG OUT */}
            {/* <IdleTimeOut onActive={handleClose} onIdle={handleOpen} /> */}
            {/* ALERTS */}
            {alerts.length > 1 ? (
                <>
                    {alerts.map((alert) => {
                        return (
                            <>
                                {showAlert ? (
                                    <Alert
                                        severity="info"
                                        variant="filled"
                                        className="alert"
                                        onClose={() => setShowAlert(false)}
                                    >
                                        {alert.message}
                                    </Alert>
                                ) : null}
                            </>
                        );
                    })}
                </>
            ) : null}
            <img
                src={logo}
                alt="Nitro Solutions Logo"
                width="18%"
                onClick={function () {
                    navigate("/home");
                }}
            />
            <nav>
                {/* ADMIN TASKS */}
                <Button
                    aria-controls="admin-menu"
                    aria-haspopup="true"
                    onClick={handleAdminMenuClick}
                    sx={{ color: "#2c53a0", fontSize: "1.1rem" }}
                >
                    Admin tasks
                </Button>
                <Menu
                    id="admin-menu"
                    anchorEl={adminMenuAnchorEl}
                    open={Boolean(adminMenuAnchorEl)}
                    onClose={handleMenuClose}
                    MenuListProps={{
                        "aria-labelledby": "admin-menu",
                    }}
                >
                    <MenuItem onClick={() => navigate("/admin/discrepancies")}>
                        Discrepancies
                    </MenuItem>
                    <MenuItem
                        onClick={() => navigate("/admin/discrepancy-letters")}
                    >
                        Discrepancy Letters
                    </MenuItem>
                    <MenuItem onClick={() => navigate("/admin/staff-banking")}>
                        Staff Banking
                        {bankChanges > 0 ? (
                            <span id="contract_alert">{bankChanges}</span>
                        ) : null}
                    </MenuItem>
                    <MenuItem onClick={() => navigate("/admin/update-company")}>
                        Update Company
                    </MenuItem>
                    <MenuItem onClick={() => navigate("/admin/create-company")}>
                        Create Company
                    </MenuItem>
                    <MenuItem onClick={() => navigate("/admin/pay-rates")}>
                        Pay Rates
                    </MenuItem>
                    <MenuItem onClick={() => navigate("/admin/roles")}>
                        Create Roles
                    </MenuItem>
                    <MenuItem onClick={() => navigate("/admin/holiday-groups")}>
                        Holiday Groups
                    </MenuItem>
                </Menu>
                {/* END ADMIN TASKS */}
                {/* ONBOARDING */}
                <Button
                    aria-controls="onboarding-menu"
                    aria-haspopup="true"
                    onClick={handleOnboardingMenuClick}
                    sx={{ color: "#2c53a0", fontSize: "1.1rem" }}
                >
                    Onboarding
                </Button>
                <Menu
                    id="onboarding-menu"
                    anchorEl={onboardingMenuAnchorEl}
                    open={Boolean(onboardingMenuAnchorEl)}
                    onClose={handleMenuClose}
                    MenuListProps={{
                        "aria-labelledby": "onboarding-menu",
                    }}
                >
                    <MenuItem onClick={() => navigate("/interview-calendar")}>
                        Interview Calendar
                    </MenuItem>
                    <MenuItem
                        onClick={() => navigate("/interview-calendar/schedule")}
                    >
                        Interview Schedule
                    </MenuItem>
                    <MenuItem onClick={() => navigate("/onboarding/status")}>
                        Onboarding Status
                    </MenuItem>
                    <MenuItem onClick={() => navigate("/contracts")}>
                        Contracts to Approve{" "}
                        {contracts > 0 ? (
                            <span id="contract_alert">{contracts}</span>
                        ) : null}
                    </MenuItem>
                    <MenuItem
                        onClick={() => navigate("/onboarding/new-manager")}
                    >
                        New Manager
                    </MenuItem>
                    <MenuItem
                        onClick={() => navigate("/onboarding/manual-contract")}
                    >
                        NSP Generation
                    </MenuItem>
                </Menu>
                {/* END ONBOARDING */}
                {/* EMPLOYEE MANAGEMENT */}
                <Button
                    aria-controls="employee-management-menu"
                    aria-haspopup="true"
                    onClick={handleEmployeeManagementMenuClick}
                    sx={{ color: "#2c53a0", fontSize: "1.1rem" }}
                >
                    Employee Management
                </Button>
                <Menu
                    id="employee-management-menu"
                    anchorEl={employeeManagementMenuAnchorEl}
                    open={Boolean(employeeManagementMenuAnchorEl)}
                    onClose={handleMenuClose}
                    MenuListProps={{
                        "aria-labelledby": "employee-management-menu",
                    }}
                >
                    <MenuItem
                        onClick={() =>
                            navigate("/employee-management/employee")
                        }
                    >
                        Employees
                    </MenuItem>
                    <MenuItem
                        onClick={() =>
                            navigate("/employee-management/holiday-calendar")
                        }
                    >
                        Holiday Calendar
                    </MenuItem>
                    <MenuItem
                        onClick={() =>
                            navigate("/employee-management/manual-leave")
                        }
                    >
                        Manual Leave
                    </MenuItem>
                    <MenuItem
                        onClick={() => navigate("/employee-management/rotas")}
                    >
                        Rotas
                    </MenuItem>
                    <MenuItem
                        onClick={() => navigate("/employee-management/leavers")}
                    >
                        Leavers
                    </MenuItem>
                    <MenuItem
                        onClick={() =>
                            navigate("/employee-management/probations")
                        }
                    >
                        Probations
                    </MenuItem>
                </Menu>
                {/* END EMPLOYEE MANAGEMENT */}
                <LogoutButton />
            </nav>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography
                        id="modal-modal-title"
                        variant="h6"
                        component="h2"
                    >
                        Your session will close soon, interact with your screen
                        to stay logged in
                    </Typography>
                </Box>
            </Modal>
        </header>
    );
}
