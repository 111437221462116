// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pl {
  margin: 2% auto;
  display: block;
  width: 6.25em;
  height: 6.25em;
}
.pl .pl__ring, .pl .pl__ball {
  animation: ring 2s ease-out infinite;
}
.pl .pl__ball {
  animation-name: ball;
}

/* Dark theme  */
@media (prefers-color-scheme: dark) {
  :root {
    --bg: hsl(var(--hue),10%,10%);
    --fg: hsl(var(--hue),10%,90%);
  }
}
/* Animation */
@keyframes ring {
  from {
    stroke-dasharray: 0 257 0 0 1 0 0 258;
  }
  25% {
    stroke-dasharray: 0 0 0 0 257 0 258 0;
  }
  50%, to {
    stroke-dasharray: 0 0 0 0 0 515 0 0;
  }
}
@keyframes ball {
  from, 50% {
    animation-timing-function: ease-in;
    stroke-dashoffset: 1;
  }
  64% {
    animation-timing-function: ease-in;
    stroke-dashoffset: -109;
  }
  78% {
    animation-timing-function: ease-in;
    stroke-dashoffset: -145;
  }
  92% {
    animation-timing-function: ease-in;
    stroke-dashoffset: -157;
  }
  57%, 71%, 85%, 99%, to {
    animation-timing-function: ease-out;
    stroke-dashoffset: -163;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/animations/loader.scss"],"names":[],"mappings":"AAAA;EACI,eAAA;EACH,cAAA;EACA,aAAA;EACA,cAAA;AACD;AAAI;EACI,oCAAA;AAER;AAAI;EACI,oBAAA;AAER;;AAEA,gBAAA;AACA;EACC;IACC,6BAAA;IACA,6BAAA;EACA;AACF;AAEA,cAAA;AACA;EACC;IACC,qCAAA;EAAA;EAED;IACC,qCAAA;EAAA;EAED;IACC,mCAAA;EAAA;AACF;AAEA;EACC;IACC,kCAAA;IACA,oBAAA;EAAA;EAED;IACC,kCAAA;IACA,uBAAA;EAAA;EAED;IACC,kCAAA;IACA,uBAAA;EAAA;EAED;IACC,kCAAA;IACA,uBAAA;EAAA;EAED;IACC,mCAAA;IACA,uBAAA;EAAA;AACF","sourcesContent":[".pl {\n    margin: 2% auto;\n\tdisplay: block;\n\twidth: 6.25em;\n\theight: 6.25em;\n    .pl__ring, .pl__ball {\n        animation: ring 2s ease-out infinite;\n    }\n    .pl__ball {\n        animation-name: ball;\n    }    \n}\n\n/* Dark theme  */\n@media (prefers-color-scheme: dark) {\n\t:root {\n\t\t--bg: hsl(var(--hue),10%,10%);\n\t\t--fg: hsl(var(--hue),10%,90%);\n\t}\n}\n\n/* Animation */\n@keyframes ring {\n\tfrom {\n\t\tstroke-dasharray: 0 257 0 0 1 0 0 258;\n\t}\n\t25% {\n\t\tstroke-dasharray: 0 0 0 0 257 0 258 0;\n\t}\n\t50%, to {\n\t\tstroke-dasharray: 0 0 0 0 0 515 0 0;\n\t}\n}\n@keyframes ball {\n\tfrom, 50% {\n\t\tanimation-timing-function: ease-in;\n\t\tstroke-dashoffset: 1;\n\t}\n\t64% {\n\t\tanimation-timing-function: ease-in;\n\t\tstroke-dashoffset: -109;\n\t}\n\t78% {\n\t\tanimation-timing-function: ease-in;\n\t\tstroke-dashoffset: -145;\n\t}\n\t92% {\n\t\tanimation-timing-function: ease-in;\n\t\tstroke-dashoffset: -157;\n\t}\n\t57%, 71%, 85%, 99%, to {\n\t\tanimation-timing-function: ease-out;\n\t\tstroke-dashoffset: -163;\n\t}\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
