import { useState, useEffect } from "react";
import axios from "axios";
import moment from "moment";
//Utils
import { getCompanies, getPositions } from "../../../../../utils/apiUtils";
import { emailRegex } from "../../../../../utils/regex";
import { encryptData } from "../../../../../utils/cryptoUtils";
//MUI
import { Button } from "@mui/material";
import { Box } from "@mui/material";
import { Alert } from "@mui/material";
//Components
import Loader from "../../../../animations/Loader";
//Styling
import "./confirm-reinstate.scss";
const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 500,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    borderRadius: "5px",
    p: 4,
};

export default function ConfirmReinstate({
    handleClose,
    rel,
    employeeId,
    employeeEmail,
    employeeFullName,
    employeeCompanyId,
    employeeRoleId,
    employeeContractedHours,
    employeeStartDate,
}) {
    const url = "";
    const [token, setToken] = useState("");
    const config = {
        headers: { "Content-Type": "application/json", "x-auth-token": token },
    };
    const [user, setUser] = useState("");
    //Form State
    const [companies, setCompanies] = useState([]);
    const [selectedCompany, setSelectedCompany] = useState(employeeCompanyId);
    const [fullName, setFullName] = useState(employeeFullName);
    const [email, setEmail] = useState(employeeEmail);
    const [roles, setRoles] = useState([]);
    const [selectedRole, setSelectedRole] = useState(employeeRoleId);
    const [startDate, setStartDate] = useState(
        moment(employeeStartDate).format("YYYY-MM-DD")
    );
    const [contractedHours, setContractedHours] = useState(
        parseFloat(employeeContractedHours)
    );
    // Alerts & Loader
    const [alert, setAlert] = useState(false);
    const [severity, setSeverity] = useState("");
    const [alertMessage, setAlertMessage] = useState("");
    const [disable, setDisable] = useState(false);
    const [loader, setLoader] = useState(false);

    useEffect(() => {
        //Get & Set Header Information
        let _user = localStorage.getItem("user");
        _user = JSON.parse(_user);
        setToken(_user.token);
        setUser(_user.user);

        //Bring in Companies
        getCompanies(_user.token)
            .then((data) => {
                setCompanies(data);
            })
            .catch((err) => {
                console.log(err);
            });
        //Bring in Company Roles
        getPositions(_user.token)
            .then((data) => {
                setRoles(data);
            })
            .catch((err) => {
                console.log(err);
            });
    }, []);

    const handleAlert = (message, severity, close = false, duration = 4000) => {
        setAlertMessage(message);
        setSeverity(severity);
        setAlert(true);
        setTimeout(() => {
            setAlert(false);
            setAlertMessage("");
            setSeverity("");
            setDisable(false);
            if (close) {
                rel();
                handleClose(false);
            }
        }, duration);
    };

    const handleReinstateValidation = () => {
        setDisable(true);
        if (selectedCompany === 0) {
            handleAlert("Please select a company", "warning");
        } else if (fullName === "") {
            handleAlert("Please enter employees full name", "warning");
        } else if (email === "" || !email.match(emailRegex)) {
            handleAlert("Please enter a valid email", "warning");
        } else if (selectedRole === 0) {
            handleAlert("Please enter a valid email", "warning");
        } else if (startDate === "") {
            handleAlert("Please enter a start date", "warning");
        } else if (contractedHours === 0) {
            handleAlert("Please enter contracted hours", "warning");
        } else {
            handleReinstateSubmit();
        }
    };

    const handleReinstateSubmit = async () => {
        const reqBody = {
            employeeId: employeeId,
            fullName: encryptData(fullName, process.env.REACT_APP_NS_ENC),
            email: encryptData(email, process.env.REACT_APP_NS_ENC),
            companyId: selectedRole,
            roleId: selectedRole,
            startDate: startDate,
            hours: contractedHours,
            user: user,
        };

        console.log(reqBody)

        await axios
            .post(`${url}/interview/new/manual`, reqBody, config)
            .then(async (res) => {
                console.log(res);
                setDisable(false);
                handleAlert("Successfully sent NSP", "success", true);
            })
            .catch((err) => {
                console.log(err);
                setDisable(false);
                handleAlert("Something went wrong - Please try again", "error");
            });
    };

    return (
        <Box sx={style} className="reinstate-modal">
            <p>
                Complete this form and submit to reinstate employee & send NSP
            </p>
            {!loader ? (
                <section className="reinstate-form">
                    <div className="form-field">
                        <label htmlFor="company">Select a Company</label>
                        <select
                            id="company"
                            value={selectedCompany}
                            onChange={(e) =>
                                setSelectedCompany(parseInt(e.target.value))
                            }
                        >
                            <option value={0}>Please select</option>
                            {companies.length > 0
                                ? companies.map((company, i) => {
                                      return (
                                          <option
                                              key={i}
                                              value={company.companyId}
                                          >
                                              {company.companyName}
                                          </option>
                                      );
                                  })
                                : null}
                        </select>
                    </div>
                    <div className="form-field">
                        <label htmlFor="employee-name">
                            Employees Full Name
                        </label>
                        <input
                            id="employee-name"
                            onChange={(e) => setFullName(e.target.value)}
                            placeholder="Enter employee full name"
                            value={fullName}
                        />
                    </div>
                    <div className="form-field">
                        <label htmlFor="employee-email">Employees Email</label>
                        <input
                            id="employee-email"
                            onChange={(e) => setEmail(e.target.value)}
                            placeholder="Enter employee email"
                            value={email}
                        />
                    </div>
                    <div className="form-field">
                        <label htmlFor="employee-role">Select a Role</label>
                        <select id="employee-role" value={selectedRole}>
                            <option value={0}>Please select</option>
                            {roles.length > 0
                                ? roles.map((role, i) => {
                                      return (
                                          <option key={i} value={role.roleId}>
                                              {role.roleName}
                                          </option>
                                      );
                                  })
                                : null}
                        </select>
                    </div>
                    <div className="form-field">
                        <label htmlFor="start-date">Start Date</label>
                        <input
                            type="date"
                            id="start-date"
                            onChange={(e) => setStartDate(e.target.value)}
                            value={startDate}
                        />
                    </div>
                    <div className="form-field">
                        <label html="contracted-hours">Contracted Hours</label>
                        <input
                            type="number"
                            id="contracted-hours"
                            onChange={(e) => setContractedHours(e.target.value)}
                            value={contractedHours}
                            placeholder="Enter contracted hours"
                            min={1}
                        />
                    </div>
                </section>
            ) : (
                <Loader />
            )}
            <section className="alert-section">
                {alert ? (
                    <Alert severity={severity}>{alertMessage}</Alert>
                ) : null}
            </section>
            <section className="btn-section">
                <Button
                    variant="contained"
                    color="error"
                    onClick={() => handleClose(false)}
                    disabled={disable}
                >
                    Close
                </Button>
                <Button
                    variant="contained"
                    color="success"
                    onClick={handleReinstateValidation}
                    disabled={disable}
                >
                    Submit
                </Button>
            </section>
        </Box>
    );
}
