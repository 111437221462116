import moment from "moment";
//MUI
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 1200,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
};

export default function HolidayReportsInfo({ setOpenInfo, holiday }) {
    return (
        <Box sx={style} className="holiday-reporting-info-modal">
            <p className="request-identifier">{holiday.holidayId}</p>
            <Button
                onClick={() => setOpenInfo(false)}
                variant="contained"
                color="error"
                className="close-modal-btn"
            >
                <CloseIcon />
            </Button>
            <h1>
                <span className="employee-name">
                    {holiday.firstName} {holiday.lastName}
                </span>{" "}
                Holiday Request
            </h1>
            <section className="main-modal-body">
                <div className="info-section">
                    <h2>Status</h2>
                    <p>
                        {" "}
                        {holiday.currentStatus === "Denied" ? (
                            <span className="denied">
                                {holiday.currentStatus}
                            </span>
                        ) : (
                            <span className="approved">
                                {holiday.currentStatus}
                            </span>
                        )}
                    </p>
                    <h2>Site</h2>
                    <p>{holiday.siteName}</p>
                    <h2>Leave Type</h2>
                    <p>{holiday.leaveType}</p>
                    <h2>Review Reason</h2>
                    <p>
                        {holiday.reviewReason
                            ? holiday.reviewReason
                            : "No review reason"}
                    </p>
                </div>{" "}
                <div className="info-section">
                    {" "}
                    <h2>Start Date</h2>
                    <p>{moment(holiday.startDate).format("DD/MM/YY")}</p>
                    <h2>Staff Role</h2>
                    <p>{holiday.roleName}</p>
                    <h2>Reason For Holiday</h2>
                    <p>
                        {holiday.reason ? holiday.reason : "No reason provided"}
                    </p>{" "}
                    <h2>Updated By</h2>
                    <p>
                        {holiday.updatedBy
                            ? holiday.updatedBy
                            : "Not actioned yet"}
                    </p>
                </div>{" "}
                <div className="info-section">
                    <h2>End Date</h2>
                    <p>{moment(holiday.endDate).format("DD/MM/YY")}</p>
                    <h2>Total Days</h2>
                    <p>{holiday.daysTaken}</p>
                    <h2>Submitted On</h2>
                    <p>
                        {moment(holiday.submissionDate).format(
                            "HH:mm DD/MM/YY"
                        )}
                    </p>
                    <h2>Updated On</h2>
                    <p>
                        {holiday.updatedDate
                            ? moment(holiday.updatedDate).format(
                                  "HH:mm DD/MM/YY"
                              )
                            : "Not actioned yet"}
                    </p>
                </div>
            </section>
        </Box>
    );
}
