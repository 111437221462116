import { useState } from "react";
import moment from "moment";
//MUI
import { Modal } from "@mui/material";
//Components
import NoteModal from "./NoteModal";

export default function NotificationRow({
    notification,
    handleReloadNotifications,
}) {
    const [openModal, setOpenModal] = useState(false);

    return (
        <>
            <tr onClick={() => setOpenModal(true)}>
                <td>{notification.notificationId}</td>
                <td>{notification.editReason}</td>
                <td>{notification.siteName}</td>
                <td>
                    {moment(notification.timeOfEdit).format(
                        "HH:mm - DD/MM/YYYY"
                    )}
                </td>
            </tr>
            <Modal open={openModal}>
                <NoteModal
                    notification={notification}
                    setOpenModal={setOpenModal}
                    handleReloadNotifications={handleReloadNotifications}
                />
            </Modal>
        </>
    );
}
