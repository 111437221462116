import { useState, useEffect } from "react";
import { Modal } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import DenyOvertime from "./DenyOvertime";
import ApproveOvertime from "./ApproveOvertime";
import CryptoJS from "crypto-js";
import moment from "moment/moment";
import InfoIcon from "@mui/icons-material/Info";
import OvertimeInfo from "./OvertimeInfo";

export default function OvertimeApprovalRow({ approval, setReload, reload }) {
    const [openApprove, setOpenApprove] = useState(false);
    const [openDeny, setOpenDeny] = useState(false);
    const [staffName, setStaffName] = useState("");
    const [openInfo, setOpenInfo] = useState(false);

    useEffect(() => {
        let fName = CryptoJS.AES.decrypt(
            approval.firstName,
            process.env.REACT_APP_EMP_KEY
        ).toString(CryptoJS.enc.Utf8);
        let lName = CryptoJS.AES.decrypt(
            approval.lastName,
            process.env.REACT_APP_EMP_KEY
        ).toString(CryptoJS.enc.Utf8);
        setStaffName(`${fName} ${lName}`);
    }, [approval]);

    return (
        <>
            <tr>
                <td>{staffName}</td>
                <td>
                    {moment(approval.actualStart).format("DD/MM/YY HH:mm")}{" "}
                    <span>-</span>
                    {moment(approval.actualEnd).format("HH:mm DD/MM/YY")}
                </td>
                <td>{approval.reasonName}</td>
                <td>
                    <InfoIcon
                        fontSize="large"
                        className="info-cell icon"
                        color="primary"
                        onClick={() => setOpenInfo(true)}
                    />
                </td>
                <td className="approve-deny-cell">
                    <CheckCircleIcon
                        color="success"
                        fontSize="large"
                        onClick={() => setOpenApprove(true)}
                        className="icon"
                    />
                    <CancelIcon
                        color="error"
                        fontSize="large"
                        onClick={() => setOpenDeny(true)}
                        className="icon"
                    />
                </td>
            </tr>
            {/* INFO MODAL */}
            <Modal open={openInfo}>
                <OvertimeInfo setOpenInfo={setOpenInfo} approval={approval} />
            </Modal>
            {/* APPROVE MODAL */}
            <Modal open={openApprove}>
                <ApproveOvertime
                    setOpenApprove={setOpenApprove}
                    setReload={setReload}
                    reload={reload}
                    id={approval.overtimeId}
                />
            </Modal>
            {/* DENY MODAL */}
            <Modal open={openDeny}>
                <DenyOvertime
                    setOpenDeny={setOpenDeny}
                    setReload={setReload}
                    reload={reload}
                    id={approval.overtimeId}
                />
            </Modal>
        </>
    );
}
