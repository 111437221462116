import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useState, useEffect } from "react";
import axios from "axios";
import Button from "@mui/material/Button";
import Alert from "@mui/material/Alert";
import moment from "moment";

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "45vw",
    bgcolor: "background.paper",
    border: "none",
    borderRadius: "1rem",
    boxShadow: 24,
    p: 4,
    maxHeight: "70vh",
    boxShadow: "none",
};

export default function ConfirmDay({
    rotaId,
    day,
    user,
    reloadData,
    handleClose,
    siteName,
}) {
    //Main - Admin
    const hrUrl = process.env.REACT_APP_API_URL;
    // const hrUrl = "http://10.0.0.34:4030";

    //Alerts
    const [alert, setAlert] = useState(false);
    const [message, setMessage] = useState("");
    const [severity, setSeverity] = useState("");

    //Alert function
    const showAlert = (message, severity, duration = 3000) => {
        setMessage(message);
        setSeverity(severity);
        setAlert(true);
        setTimeout(() => {
            setAlert(false);
            setMessage("");
            setSeverity("");
        }, duration);
    };
    //Confirm full day
    const handleConfirmFullDay = async () => {
        let data = {
            rotaId: rotaId,
            date: day,
            user: user,
        };
        console.log(data);

        await axios
            .post(`${hrUrl}/rota/confirm/day`, data)
            .then((res) => {
                showAlert("Day successfully confirmed", "success");
                reloadData();
                handleClose(false);
            })
            .catch((err) => {
                console.log(err);
                showAlert("Something went wrong - Please try again", "error");
            });
    };

    return (
        <Box sx={style} className="publish_modal">
            <Typography id="modal-modal-title" variant="h6" component="h2">
                Confirm Day Check
            </Typography>

            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                You are about to confirm the full date:{" "}
                <b>{moment(day, "YYYY-MM-DD").format("DD-MM-YYYY")}</b> for the{" "}
                <b>{siteName}</b> rota. This action is non-reversable, so please
                make sure you are happy with all shifts for this day before
                proceeding.
            </Typography>

            <Typography className="publish_btns">
                {!alert ? (
                    <>
                        <Button
                            variant="contained"
                            color="error"
                            onClick={() => handleClose(false)}
                        >
                            close
                        </Button>

                        <Button
                            variant="contained"
                            color="success"
                            onClick={handleConfirmFullDay}
                        >
                            Confirm
                        </Button>
                    </>
                ) : (
                    <Alert sx={{ width: "100%" }} severity={severity}>
                        {message}
                    </Alert>
                )}
            </Typography>
        </Box>
    );
}
