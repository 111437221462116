import { useState, useEffect } from "react";
import "./overtime-approval.scss";
import axios from "axios";
import OvertimeApprovalRow from "./OvertimeApprovalRow";

export default function OvertimeApproval() {
    const [reload, setReload] = useState(false);
    const [approvals, setApprovals] = useState([]);

    useEffect(() => {
        axios
            .get(
                `${process.env.REACT_APP_API_URL}/notifications/overtime/active`
            )
            .then((res) => {
                console.log(res);
                setApprovals(res.data);
            })
            .catch((err) => {
                console.log(err);
            });
    }, [reload]);
    return (
        <main className="overtime-approval">
            <h1 className="heading-master">Overtime Approval</h1>
            <section className="ot-approval-container">
                {approvals.length > 0 ? (
                    <table className="ot-approval">
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Date</th>
                                <th>Reason</th>
                                <th>Info</th>
                                <th>Approve/Deny</th>
                            </tr>
                        </thead>
                        <tbody>
                            {approvals.map((approval, i) => {
                                return (
                                    <OvertimeApprovalRow
                                        key={i}
                                        approval={approval}
                                        setReload={setReload}
                                        reload={reload}
                                    />
                                );
                            })}
                        </tbody>
                    </table>
                ) : (
                    <h2 className="no-approvals">
                        There are no overtime approvals at the moment. Check
                        back later.
                    </h2>
                )}
            </section>
        </main>
    );
}
