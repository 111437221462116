import Box from "@mui/material/Box";
import { useState, useEffect } from "react";

const style = {
    width: "100%",
    maxheight: "10%",
    bgcolor: "background.paper",
    border: "none",
    boxShadow: 24,
    p: 4,
    maxHeight: "20vh",
    overflow: "auto",
    "@media(max-width: 690px)": {
        width: "90vw",
        maxHeight: "90vh",
    },
};

const LogsTab = ({ _logs }) => {

    return (
        <Box style={style}>

            {!!_logs ? (
                <table className="modal__tables notes_table">
                    <thead
                        style={{ backgroundColor: "#2c53a0", color: "#fff" }}
                    >
                        <tr>
                            <th style={{ width: "5%" }}>#</th>
                            <th style={{ width: "40%" }}>Log</th>
                            <th style={{ width: "30%" }}>User</th>
                            <th style={{ width: "20%" }}>Date / Time</th>
                        </tr>
                    </thead>
                    {_logs.map((log, i) => {
                        return (
                            <tr>
                                <td>{i + 1}</td>
                                <td>{log.note}</td>
                                <td>{log.user}</td>
                                <td>{log.dateAdded}</td>
                            </tr>
                        )
                    })}
                </table>
            ) : (
                <>
                    NO LOGS YET, DO SOMETHING AND WELL MAKE SOME
                </>
            )}


        </Box>
    )

}

export default LogsTab;