import { useState, useEffect } from "react";
import moment from "moment";
import axios from "axios";
//MUI
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CloseIcon from "@mui/icons-material/Close";

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 1200,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
};

export default function HolidayReportsInfo({ setOpenInfo, holiday, header }) {
    const url = process.env.REACT_APP_API_URL;
    const [logs, setLogs] = useState([]);

    useEffect(() => {
        fetchLogs();
    }, []);

    const fetchLogs = async () => {
        await axios
            .get(`${url}/holiday/admin/${holiday.holidayId}/logs`, header)
            .then((res) => {
                setLogs(res.data);
            })
            .catch((err) => {
                console.log(err);
            });
    };

    return (
        <Box sx={style} className="holiday-reporting-info-modal">
            <p className="request-identifier">{holiday.holidayId}</p>
            <Button
                onClick={() => setOpenInfo(false)}
                variant="contained"
                color="error"
                className="close-modal-btn"
            >
                <CloseIcon />
            </Button>
            <h1>
                <span className="employee-name">
                    {holiday.firstName} {holiday.lastName}
                </span>{" "}
                Holiday Request
            </h1>
            <section className="main-modal-body">
                <div className="info-section">
                    <h2>Status</h2>
                    <p>
                        {" "}
                        {holiday.currentStatus === "Denied" ? (
                            <span className="denied">
                                {holiday.currentStatus}
                            </span>
                        ) : (
                            <span className="approved">
                                {holiday.currentStatus}
                            </span>
                        )}
                    </p>
                    <h2>Site</h2>
                    <p>{holiday.siteName}</p>
                    <h2>Leave Type</h2>
                    <p>{holiday.leaveType}</p>
                    {logs.length === 0 ? (
                        <>
                            <h2>Submitted On</h2>
                            <p>
                                {moment(holiday.submissionDate).format(
                                    "HH:mm DD/MM/YY"
                                )}
                            </p>
                        </>
                    ) : null}
                </div>{" "}
                <div className="info-section">
                    {" "}
                    <h2>Start Date</h2>
                    <p>{moment(holiday.startDate).format("DD/MM/YY")}</p>
                    <h2>Staff Role</h2>
                    <p>{holiday.roleName}</p>
                    <h2>Reason For Holiday</h2>
                    <p>
                        {holiday.reason ? holiday.reason : "No reason provided"}
                    </p>
                    {logs.length === 0 ? (
                        <>
                            <h2>Updated By</h2>
                            <p>{holiday.updatedBy}</p>
                        </>
                    ) : null}
                </div>{" "}
                <div className="info-section">
                    <h2>End Date</h2>
                    <p>{moment(holiday.endDate).format("DD/MM/YY")}</p>
                    <h2>Total Days</h2>
                    <p>{holiday.daysTaken}</p>
                    <h2>Review Reason</h2>
                    <p>
                        {holiday.reviewReason
                            ? holiday.reviewReason
                            : "No review reason"}
                    </p>
                    {logs.length === 0 ? (
                        <>
                            <h2>Updated On</h2>
                            <p>
                                {moment(holiday.updatedDate).format(
                                    "HH:mm DD/MM/YY"
                                )}
                            </p>
                        </>
                    ) : null}
                </div>
            </section>

            {logs.length > 0 ? (
                <section className="log-table-container">
                    <table className="log-table">
                        <thead>
                            <tr>
                                <th>Date / Time</th>
                                <th>Action</th>
                                <th>User</th>
                            </tr>
                        </thead>
                        <tbody>
                            {logs.map((log, i) => {
                                return (
                                    <tr key={i}>
                                        <td>
                                            {" "}
                                            {moment(log.logDate).format(
                                                "HH:mm DD/MM/YY"
                                            )}
                                        </td>
                                        <td>{log.log}</td>
                                        <td>{log.user}</td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </section>
            ) : null}
        </Box>
    );
}
