import {
    getCompanies,
    getEmployeesByCompany,
} from "../../../../utils/apiUtils";
import "./newManager.scss";
import { useEffect, useState } from "react";
import Alert from "@mui/material/Alert";
import HourglassEmptyIcon from "@mui/icons-material/HourglassEmpty";
import { decryptData } from "../../../../utils/cryptoUtils";
import NewManagerTable from "./NewManagerTable";
import { Button } from "@mui/material";
import axios from "axios";

export default function NewManager() {
    const [siteList, setSiteList] = useState([]);
    const [staffList, setStaffList] = useState([]);
    const [selectedEmployee, setSelectedEmployee] = useState("");
    const url = process.env.REACT_APP_API_URL;
    const [token, setToken] = useState("");
    const config = {
        headers: { "Content-Type": "application/json", "x-auth-token": token },
    };

    //alerts
    const [showAlert, setShowAlert] = useState(false);
    const [severity, setSeverity] = useState("");
    const [message, setMessage] = useState("");

    useEffect(() => {
        let token = localStorage.getItem("user");
        let getToken = JSON.parse(token);
        let final = getToken.token;
        setToken(final);

        getCompanies(final)
            .then((sites) => {
                setSiteList(sites);
            })
            .catch((err) => {
                console.log(`Could not get all sites: ${err}`);
            });
    }, []);

    const handleEmployees = (storeId) => {
        setStaffList([]);
        setSelectedEmployee("");
        if (storeId === "--Please select one--") {
            document.getElementById("new_manager").style.display = "none";
            setSeverity("warning");
            setMessage("Please select a store");
            setShowAlert(true);
            setTimeout(() => {
                setShowAlert(false);
                setSeverity("");
                setMessage("");
                document.getElementById("new_manager").style.display = "flex";
            }, 2500);
        } else {
            getEmployeesByCompany(storeId, token)
                .then((employees) => {
                    let list = employees;
                    for (let e = 0; e < list.length; e++) {
                        list[e].firstName = decryptData(
                            list[e].firstName,
                            process.env.REACT_APP_EMP_KEY
                        );
                        list[e].lastName = decryptData(
                            list[e].lastName,
                            process.env.REACT_APP_EMP_KEY
                        );
                    }
                    setStaffList(list);
                })
                .catch((err) => {
                    console.log(`Could not get employees: ${err}`);
                });
        }
    };

    const handleEmployee = (employeeId) => {
        setSelectedEmployee("");
        if (employeeId === "--Please select one--") {
            document.getElementById("new_manager").style.display = "none";
            setSeverity("warning");
            setMessage("Please select an employee");
            setShowAlert(true);
            setTimeout(() => {
                setShowAlert(false);
                setSeverity("");
                setMessage("");
                document.getElementById("new_manager").style.display = "flex";
                setSelectedEmployee("");
            }, 2500);
        } else {
            setSelectedEmployee(employeeId);
        }
    };

    const submit = async () => {
        document.getElementById("new_manager").style.display = "none";
        if (
            selectedEmployee == "" ||
            selectedEmployee == "--Please select one--"
        ) {
            setSeverity("warning");
            setMessage("Please select an employee");
            setShowAlert(true);
            setTimeout(() => {
                setShowAlert(false);
                setSeverity("");
                setMessage("");
                document.getElementById("new_manager").style.display = "flex";
                setSelectedEmployee("");
            }, 2500);
        } else {
            await axios
                .post(
                    `${url}/onBoard/work-email`,
                    { employeeDetailsId: parseInt(selectedEmployee) },
                    config
                )
                .then((res) => {
                    setMessage("New account alert has been sent to IT!");
                    setSeverity("success");
                    setShowAlert(true);
                    setTimeout(() => {
                        setShowAlert(false);
                    }, 3000);
                })
                .catch((err) => {
                    console.log(err);
                    setMessage(err.response.data);
                    setShowAlert(true);
                    setTimeout(() => {
                        setShowAlert(false);
                        setSeverity("");
                        setMessage("");
                        document.getElementById("new_manager").style.display =
                            "flex";
                    }, 2500);
                });
        }
    };

    return (
        <main className="new_manager_main main">
            <h1 className="heading-master">New Manager Form</h1>
            <section className="new__manager">
                <aside className="new_manager_form">
                    <h2>Form</h2>
                    <div className="form">
                        <label>
                            Site<span>*</span>
                        </label>
                        <select
                            onChange={(e) => handleEmployees(e.target.value)}
                        >
                            <option>--Please select one--</option>
                            {siteList.map((site) => {
                                return (
                                    <option
                                        key={site.companyId}
                                        value={site.companyId}
                                    >
                                        {site.siteName}
                                    </option>
                                );
                            })}
                        </select>
                        <label>
                            Staff Name <span>*</span>
                        </label>
                        {staffList.length < 1 ? (
                            <HourglassEmptyIcon className="loading_icon" />
                        ) : (
                            <select
                                onChange={(e) => handleEmployee(e.target.value)}
                            >
                                <option>--Please select one--</option>
                                {staffList.map((employee) => {
                                    return (
                                        <option
                                            key={employee.employeeDetailsId}
                                            value={employee.employeeDetailsId}
                                        >
                                            {employee.firstName}{" "}
                                            {employee.lastName}
                                        </option>
                                    );
                                })}
                            </select>
                        )}
                    </div>
                    {showAlert ? (
                        <Alert severity={severity}>{message}</Alert>
                    ) : null}
                    <Button
                        variant="contained"
                        id="new_manager"
                        onClick={submit}
                        disabled={selectedEmployee == ""}
                    >
                        Submit form
                    </Button>
                </aside>
                <NewManagerTable />
            </section>
        </main>
    );
}
