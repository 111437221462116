const style = {
    textAlign: "center",
    textTransform: "uppercase",
    fontSize: "2.2rem",
};

export default function NoAccess() {
    return (
        <section className="main">
            <section className="home">
                <h1 style={style}>
                    You don't have access to this page, please navigate to your
                    allowed pages
                </h1>
            </section>
        </section>
    );
}
