//React
import { useEffect, useState } from "react";
import axios from "axios";
import moment from "moment";
//Components
import Loader from "../animations/Loader";
//MUI
import DoDisturbAltIcon from "@mui/icons-material/DoDisturbAlt";

export default function CompanyDash({ site, month }) {
    const [unpublished, setUnpublished] = useState({});
    const [unconfirmed, setUnconfirmed] = useState({});
    const [unconfirmedOverTwoDays, setUnconfirmedOverTwoDays] = useState();
    const [weekData,setWeekData] = useState([])
    const [hoursWorked, setHoursWorked] = useState("");
    const [loader, setLoader] = useState(false);
    const [alert, setAlert] = useState(false);

    useEffect(() => {
        // TODO: ENDPOINT TO TAKE MONTH
        setLoader(true);

        axios
            .get(
                `${process.env.REACT_APP_API_URL}/dashboard/rota/shifts/${site.companyId}/${month}`
            )
            .then((res) => {
               
                setUnpublished(res.data.unpublished);
                setUnconfirmed(res.data.unconfirmed);
                setHoursWorked(res.data.hoursWorked);
                setWeekData(res.data.weekData)

                //Find how many unconfirmed shifts are over two days old
                let overTwoDays = 0;
                let twoDaysAgo = moment().subtract(2, "days").format();
                res.data.unconfirmed.shiftData.filter((d, i) => {
                    if (d.startTime < twoDaysAgo) {
                        overTwoDays += 1;
                    }
                });
                setUnconfirmedOverTwoDays(overTwoDays);
                setLoader(false);
            })
            .catch((err) => {
                console.log(err);
                setLoader(false);
                setAlert(true);
            });
    }, [month]);

    return (
        <>
            {!loader ? (
                <section className="dashboard_card">
                    <h3 className="dashboard_sitename">{site.siteName}</h3>
                    {!alert ? (
                        <>
                            <section className="monthly_totals">
                            <br/>
                                <table>
                                    <tbody>
                                        <tr>
                                            <th>Unpublished</th>
                                            <td>{unpublished.count}</td>
                                        </tr>
                                        <tr>
                                            <th>Unconfirmed</th>
                                            <td>{unconfirmed.count}</td>
                                        </tr>
                                        <tr>
                                            <th>Unconfirmed ( 2+ days)</th>
                                            <td className="unconfirmed">
                                                {unconfirmedOverTwoDays}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </section>
                            <section className="weekly_breakdown">
                                <br/>
                                <table>
                                    <thead>
                                        <tr>
                                            <th></th>
                                            <th>Unpublished</th>
                                            <th>Unconfirmed</th>
                                            <th>Unconfirmed (2days+)</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {weekData.map(function(d,i){
                                            return(
                                                <tr>
                                                <td>W/C {moment(d.start,"YYYY-MM-DD").format("DD/MM/YY")}</td>
                                                <td>{d.unpublished}</td>
                                                <td>{d.unconfirmed}</td>
                                                <td className="unconfirmed">{d.unconfirmedOlder}</td>
                                            </tr>  
                                            )
                                        })}
                                    </tbody>
                                </table>
                            </section>
                        </>
                    ) : (
                        <div className="indv_site_alert">
                            <DoDisturbAltIcon
                                fontSize="large"
                                className="alert_sign"
                            />
                            <h4>Sorry, can't get info for {site.siteName}</h4>
                        </div>
                    )}
                </section>
            ) : (
                <div className="loader">
                    <Loader />
                </div>
            )}
        </>
    );
}
