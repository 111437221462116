import { useState, useEffect } from "react";
import axios from "axios";
import "./login.scss";
import Alert from "@mui/material/Alert";
import { useNavigate } from "react-router-dom";
import { test } from "../../utils/apiUtils";

export default function Login({ setLoggedIn }) {
    const [login, setLogin] = useState(true);
    // const [forgotPass, setForgotPass] = useState(false)
    const [message, setMessage] = useState("");
    const [alert, setAlert] = useState(false);
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const navigate = useNavigate();

    useEffect(() => {
        localStorage.clear();
        let preLoaded = sessionStorage.getItem("first-load");
        if (preLoaded == null) {
            sessionStorage.setItem("first-load", true);
            caches.keys().then((names) => {
                names.forEach((name) => {
                    caches.delete(name);
                });
            });
            console.log("Cache cleared");
        }
    }, []);

    //function to log in
    const handleAccess = async (e) => {
        e.preventDefault();

        const regex = /^[A-Za-z]+\.[A-Za-z]+$/;
        if (username.match(regex)) {
            try {
                const user = { username, password };
                const loginRes = await axios.post(
                    process.env.REACT_APP_LOGIN_ENDPOINT,
                    user
                );
                //set user data to
                let userData = {
                    token: loginRes.data.token,
                    user: loginRes.data.user.name,
                    id: loginRes.data.user.accessId,
                };
                localStorage.setItem("user", JSON.stringify(userData));
                localStorage.setItem("userName", JSON.stringify(username));
                // test();
                setTimeout(() => {
                    setLoggedIn(true);
                    navigate("/home");
                }, 500);
            } catch (err) {
                document.getElementById("submit_btn").style.display = "none";
                setAlert(true);
                setMessage(err.response.data);
                setTimeout(() => {
                    setAlert(false);
                    setMessage("");
                    document.getElementById("submit_btn").style.display =
                        "flex";
                }, 2500);
            }
        } else {
            document.getElementById("submit_btn").style.display = "none";
            setMessage("Username or password is wrong, please try again");
            setAlert(true);
            setTimeout(() => {
                setAlert(false);
                setMessage("");
                document.getElementById("submit_btn").style.display = "flex";
            }, 2500);
        }
    };

    return (
        <main className="login">
            <section className="logo"></section>
            <section className="login_container">
                <div className="login_form">
                    <form onSubmit={handleAccess}>
                        <input
                            type="text"
                            placeholder="Username"
                            name="username"
                            onChange={(e) => setUsername(e.target.value)}
                        />
                        <input
                            type="password"
                            placeholder="Password"
                            name="password"
                            onChange={(e) => setPassword(e.target.value)}
                        />
                        <input type="submit" value="Login" id="submit_btn" />
                        {alert ? (
                            <Alert severity="error">{message}</Alert>
                        ) : (
                            <></>
                        )}
                    </form>
                </div>
            </section>
        </main>
    );
}
