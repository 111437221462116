import { useEffect, useState } from "react";
import axios from "axios";
//Components
import NotificationRow from "./NotificationRow";
//MUI
import { Button, Box } from "@mui/material";

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "50vw",
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
};

export default function Notifications({ setOpenNotifications }) {
    const [notifications, setNotifications] = useState([]);
    const [reloadNotifications, setReloadNotifications] = useState(false);

    useEffect(() => {
        getNotifications();
    }, [reloadNotifications]);

    const handleReloadNotifications = () => {
        setReloadNotifications(!reloadNotifications);
    };

    const getNotifications = () => {
        axios
            .get(`${process.env.REACT_APP_API_URL}/notifications/active`)
            .then((res) => {
                setNotifications(res.data);
            })
            .catch((err) => {
                console.log(err);
            });
    };

    return (
        <Box sx={style}>
            <section className="notification-container">
                <table className="notification-table">
                    <thead>
                        <th>Id</th>
                        <th>Notification</th>
                        <th>Site</th>
                        <th>Date/Time</th>
                    </thead>
                    <tbody>
                        {notifications.length == 0 ? (
                            <tr>
                                <td colSpan={5}>
                                    <h4>
                                        Well done! You are all caught up with
                                        your notifications!
                                    </h4>
                                </td>
                            </tr>
                        ) : (
                            notifications.map(function (notification, i) {
                                return (
                                    <NotificationRow
                                        key={i}
                                        notification={notification}
                                        handleReloadNotifications={
                                            handleReloadNotifications
                                        }
                                    />
                                );
                            })
                        )}
                    </tbody>
                </table>
            </section>
            <Button
                variant="contained"
                color="error"
                onClick={() => setOpenNotifications(false)}
            >
                Close
            </Button>
        </Box>
    );
}
