import "./leave.scss";
import { useEffect, useState } from "react";
import axios from "axios";
import moment from "moment";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import IconButton from "@mui/material/IconButton";
import Loader from "../../../animations/Loader";
import Alert from "@mui/material/Alert";
import IndvHols from "./InvHols";
import { getCompanies } from "../../../../utils/apiUtils";

export default function HolidayCalendar() {
    const [token, setToken] = useState("");
    const [siteList, setSiteList] = useState([]);
    const [site, setSite] = useState("");
    const [siteName, setSiteName] = useState("");
    const [currentMonth, setCurrentMonth] = useState("");
    const today = new Date();
    const [monthArray, setMonthArray] = useState([]);
    const [currentYear, setCurrentYear] = useState("");
    const [employeeList, setEmployeeList] = useState([]);
    const [showLoader, setShowLoader] = useState(false);
    const [singleSite, setSingleSite] = useState(false);
    const [month, setMonth] = useState();
    const [type, setType] = useState("HR");
    //alerts
    const [showAlert, setShowAlert] = useState(false);
    const [severity, setSeverity] = useState("");
    const [message, setMessage] = useState("");

    const urlHr = process.env.REACT_APP_API_URL;

    const config = {
        headers: { "x-auth-token": token },
    };

    useEffect(() => {
        //checks the token for the HR BE
        let user = localStorage.getItem("user");
        let parseU = JSON.parse(user);
        let localToken = parseU.token;
        setToken(localToken);

        //shows the current month
        let year = new Date(today).getFullYear();
        let month = new Date(today).getMonth() + 1;
        setMonth(month);
        let daysInMonth = new Date(year, month, 0).getDate();
        let daysArray = Array.from({ length: daysInMonth }, (x, i) => i);
        setMonthArray(daysArray);
        setCurrentMonth(moment.months(month - 1));
        setCurrentYear(year);
        getCompanies(localToken)
            .then((sites) => {
                setSiteList(sites);
            })
            .catch((err) => {
                console.log(`Could not get sites: ${err}`);
            });
    }, []);

    const handleSite = (site) => {
        if (site === "--Select One--") {
            setSite("");
        } else {
            setShowLoader(true);
            for (let i = 0; i < siteList.length; i++) {
                if (siteList[i].eposId == site) {
                    setSiteName(siteList[i].siteName);
                }
            }
            setSite(site);
            let data = {
                month: month,
                year: currentYear,
                siteId: site,
                type: type,
            };

            axios
                .post(`${urlHr}/calendar/staff`, data, config)
                .then((res) => {
                    // console.log(res.data)
                    setEmployeeList(res.data);
                    setShowLoader(false);
                })
                .catch((err) => {
                    console.log(err);
                    if (err.response.data.error) {
                        setMessage(err.response.data.error);
                        setShowLoader(false);
                        setSeverity("error");
                        setShowAlert(true);
                        setTimeout(() => {
                            setShowAlert(false);
                            setSeverity("");
                            setMessage("");
                            setSite("");
                        }, 4000);
                    }
                });
        }
    };

    //handles go back one month
    const handleBack = async () => {
        setShowLoader(true);
        let currentmonth = moment().month(currentMonth).format("M");
        let month = currentmonth - 1;
        let currentYear2;
        //if the month is January will account for the year
        if (currentmonth == 1) {
            var v2 = moment(`${currentYear}`, "YYYY")
                .subtract(1, "years")
                .format("YYYY");
            currentYear2 = parseInt(v2);
            setCurrentYear(currentYear2);
        } else {
            currentYear2 = parseInt(currentYear);
        }
        let daysInSelect = new Date(currentYear2, month, 0).getDate();
        let daysArray = Array.from({ length: daysInSelect }, (x, i) => i);
        setMonthArray(daysArray);
        setCurrentMonth(moment.months(month - 1));

        if (month == 0) {
            month = 12;
        }

        //axios request to get employees leave for that site and month
        let data = {};
        if (site == "RTMS") {
            data = {
                month: month,
                year: currentYear2,
                siteId: 0,
                type: type,
            };
        } else if (site === null) {
            data = {
                month: month,
                year: currentYear2,
                siteId: 24195,
                type: type,
                role: "Warehouse Manager",
            };
        } else {
            data = {
                month: month,
                year: currentYear2,
                siteId: site,
                type: type,
            };
        }

        await axios
            .post(`${urlHr}/calendar/staff`, data, config)
            .then((res) => {
                setEmployeeList(res.data);
                setShowLoader(false);
            })
            .catch((err) => {
                console.log(err);
                if (err.response.data.error) {
                    setMessage(err.response.data.error);
                    setShowLoader(false);
                    setSeverity("error");
                    setShowAlert(true);
                    setTimeout(() => {
                        setShowAlert(false);
                        setSeverity("");
                        setMessage("");
                        setSite("");
                    }, 4000);
                }
            });
    };

    //handle go forward one month
    const handleForth = async () => {
        setShowLoader(true);
        let currentmonth = moment().month(currentMonth).format("M");
        let month = parseInt(currentmonth) + 1;

        let currentYear2;
        //if the month is December will account for the year
        if (currentmonth == 12) {
            var v2 = moment(`${currentYear}`, "YYYY")
                .add(1, "years")
                .format("YYYY");
            currentYear2 = parseInt(v2);
            setCurrentYear(currentYear2);
        } else {
            currentYear2 = parseInt(currentYear);
        }
        let daysInSelect = new Date(currentYear2, month, 0).getDate();
        let daysArray = Array.from({ length: daysInSelect }, (x, i) => i);
        setMonthArray(daysArray);
        setCurrentMonth(moment.months(month - 1));
        if (month == 13) {
            month = 1;
        }
        //axios request to get employees leave for that site and month
        let data = {};
        if (site == "RTMS") {
            data = {
                month: month,
                year: currentYear2,
                siteId: 0,
                type: type,
            };
        } else if (site === null) {
            data = {
                month: month,
                year: currentYear2,
                siteId: 24195,
                type: type,
                role: "Warehouse Manager",
            };
        } else {
            data = {
                month: month,
                year: currentYear2,
                siteId: site,
                type: type,
            };
        }

        await axios
            .post(`${urlHr}/calendar/staff`, data, config)
            .then((res) => {
                setEmployeeList(res.data);
                setShowLoader(false);
            })
            .catch((err) => {
                console.log(err);
                if (err.response.data.error) {
                    setMessage(err.response.data.error);
                    setShowLoader(false);
                    setSeverity("error");
                    setShowAlert(true);
                    setTimeout(() => {
                        setShowAlert(false);
                        setSeverity("");
                        setMessage("");
                        setSite("");
                    }, 4000);
                }
            });
    };

    const reload = () => {
        let data = {
            month: month,
            year: currentYear,
            siteId: site,
            type: type,
        };

        axios
            .post(`${urlHr}/calendar/staff`, data, config)
            .then((res) => {
                setEmployeeList(res.data);
                setShowLoader(false);
            })
            .catch((err) => {
                console.log(err);
                if (err.response.data.error) {
                    setMessage(err.response.data.error);
                    setShowLoader(false);
                    setSeverity("error");
                    setShowAlert(true);
                    setTimeout(() => {
                        setShowAlert(false);
                        setSeverity("");
                        setMessage("");
                        setSite("");
                    }, 4000);
                }
            });
    };

    return (
        <main className="hol_calendar main">
            <h1 className="heading-master">Holiday Calendar</h1>
            <section className="team__calendar">
                {!singleSite ? (
                    <>
                        <label for="site" className="main__label">
                            Which calendar do you want to see?
                        </label>
                        <select
                            name="site"
                            onChange={(e) => handleSite(e.target.value)}
                        >
                            <option>--Select One--</option>
                            {siteList.map(function (site, i) {
                                return (
                                    <option value={site.eposId}>
                                        {site.siteName}
                                    </option>
                                );
                            })}
                        </select>
                    </>
                ) : null}
                {site != "" ? (
                    <>
                        {showLoader ? (
                            <Loader />
                        ) : showAlert ? (
                            <Alert severity={severity} sx={{ marginTop: "2%" }}>
                                {message}, please log out and log back in again
                            </Alert>
                        ) : (
                            <section className="calendar_view">
                                <h2>You are viewing {siteName}</h2>
                                <div className="month__view">
                                    <IconButton onClick={() => handleBack()}>
                                        <ArrowBackIosIcon className="arrow_icon" />
                                    </IconButton>
                                    <h6>
                                        {currentMonth} {currentYear}
                                    </h6>
                                    <IconButton onClick={() => handleForth()}>
                                        <ArrowForwardIosIcon className="arrow_icon" />
                                    </IconButton>
                                </div>
                                <table style={{ marginBottom: "3%" }}>
                                    <thead>
                                        <td colspan={4}>Name</td>
                                        {monthArray.map(function (day, i) {
                                            var dayNo = moment().format("D");
                                            var month = moment().format("MMMM");
                                            var year = moment().format("YYYY");
                                            var bg;
                                            dayNo == i + 1 &&
                                            month == currentMonth &&
                                            year == currentYear
                                                ? (bg = "lightblue")
                                                : (bg = "");
                                            var dayName = moment(
                                                `${
                                                    i + 1
                                                }/${currentMonth}/${currentYear}`,
                                                "DD/MMMM/YYYY"
                                            )
                                                .format("dddd")
                                                .substring(0, 3);
                                            return (
                                                <td
                                                    style={{
                                                        backgroundColor: bg,
                                                    }}
                                                >
                                                    {i + 1}
                                                    <br />
                                                    {dayName}
                                                </td>
                                            );
                                        })}
                                    </thead>
                                    <tbody>
                                        {employeeList.map(function (
                                            employee,
                                            i
                                        ) {
                                            return (
                                                <IndvHols
                                                    employee={employee}
                                                    month={monthArray}
                                                    currentMonth={currentMonth}
                                                    currentYear={currentYear}
                                                    rel={reload}
                                                    site={site}
                                                />
                                            );
                                        })}
                                    </tbody>
                                </table>
                            </section>
                        )}
                    </>
                ) : null}
            </section>
        </main>
    );
}
