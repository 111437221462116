import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useState, useEffect } from "react";
import axios from "axios";
import Button from "@mui/material/Button";
import Alert from "@mui/material/Alert";

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "40vw",
    bgcolor: "background.paper",
    border: "none",
    borderRadius: "1rem",
    boxShadow: 24,
    p: 4,
    maxHeight: "70vh",
};

export default function RemoveEmployee({
    siteName,
    setRemoveEmployeeModal,
    firstName,
    lastName,
    rotaId,
    employee,
    reloadData,
}) {

    //Alerts
    const [alert, setAlert] = useState(false);
    const [message, setMessage] = useState("");
    const [severity, setSeverity] = useState("");
    const [disable, setDisable] = useState(false);

    const showAlert = (message, severity) => {
        setDisable(true);
        setMessage(message);
        setSeverity(severity);
        setAlert(true);
        setTimeout(() => {
            setDisable(false);
            setAlert(false);
            setMessage("message");
            setSeverity("severity");
        }, 3000);
    };

    const handleRemoveEmployee = () => {
        let data = {
            rotaId: rotaId,
            employee: employee.employeeDetailsId,
        };

        axios
            .post(`${process.env.REACT_APP_API_URL}/rota/employee/delete`, data)
            .then((res) => {
                showAlert("Employee successfully removed", "success");
                setDisable(false);
                reloadData();
                setRemoveEmployeeModal(false);
            })
            .catch((err) => {
                console.log(err);
                showAlert("Employee could not be removed", "error");
            });
    };
    return (
        <Box sx={style}>
            <Typography id="modal-modal-title" variant="h6" component="h1">
                Remove Employee
            </Typography>

            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                <div>
                    <p>
                        <b>
                            {firstName} {lastName}
                        </b>{" "}
                        does not usually work from <b>{siteName}</b>. They have
                        been added for this week.
                        <br />
                        <br /> This will delete all shifts for &nbsp;
                        <b>
                            {firstName} {lastName}
                        </b>{" "}
                        at <b>{siteName}</b> for this week. Are you sure you
                        want to remove them?
                    </p>
                    {alert ? (
                        <Alert severity={severity}>{message}</Alert>
                    ) : (
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                                marginTop: "3%",
                            }}
                        >
                            <Button
                                variant="contained"
                                color="error"
                                onClick={() => setRemoveEmployeeModal(false)}
                                disable={disable}
                            >
                                Close
                            </Button>
                            <Button
                                variant="contained"
                                color="primary"
                                id="addNote-btn"
                                onClick={handleRemoveEmployee}
                                disable={disable}
                            >
                                Remove
                            </Button>
                        </div>
                    )}
                </div>
            </Typography>
        </Box>
    );
}
