import "./admin.scss";
import { getPositions } from "../../../utils/apiUtils";
import { useEffect, useState } from "react";
import { Button, Alert } from "@mui/material";
import DataSaverOnIcon from "@mui/icons-material/DataSaverOn";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import axios from "axios";

export default function CreateRoles() {
    const [positions, setPositions] = useState([]);
    const [name, setName] = useState("");
    const [description, setDescription] = useState("");
    const [user, setUser] = useState("");
    const [reload, setReload] = useState(false);
    const [token, setToken] = useState("");
    //alerts
    const [showAlert, setShowAlert] = useState(false);
    const [message, setMessage] = useState("");
    const [severity, setSeverity] = useState("");
    const config = {
        headers: { "Content-Type": "application/json", "x-auth-token": token },
    };

    useEffect(() => {
        let checkId = localStorage.getItem("user");
        let parsed = JSON.parse(checkId);
        let token = parsed.token;
        setToken(token);
        setUser(parsed.user);

        getPositions(token)
            .then((data) => {
                setPositions(data);
            })
            .catch((err) => {
                console.log(err);
            });
    }, [reload]);

    const addPosition = () => {
        document.getElementById("position_btn").style.display = "none";
        if (name === "") {
            setSeverity("warning");
            setMessage("Please insert a name for the position");
            setShowAlert(true);
            setTimeout(() => {
                setShowAlert(false);
                setSeverity("");
                setMessage("");
                document.getElementById("position_btn").style.display = "flex";
            }, 3000);
        } else {
            let data = {
                user: user,
                roleName: name,
                description: description,
            };
            axios
                .post(
                    `${process.env.REACT_APP_API_URL}/roles/add`,
                    data,
                    config
                )
                .then((res) => {
                    // console.log(res.data);
                    setSeverity("success");
                    setMessage("New role has been added to the data base");
                    setShowAlert(true);
                    setTimeout(() => {
                        setShowAlert(false);
                        setSeverity("");
                        setMessage("");
                        document.getElementById("position_btn").style.display =
                            "flex";
                        setName("");
                        setDescription("");
                        setReload(!reload);
                    }, 3000);
                })
                .catch((err) => {
                    setSeverity("error");
                    setMessage("Something went wrong, please try again");
                    setShowAlert(true);
                    setTimeout(() => {
                        setShowAlert(false);
                        setSeverity("");
                        setMessage("");
                        document.getElementById("position_btn").style.display =
                            "flex";
                    }, 3000);
                });
        }
    };

    const deletePosition = (id) => {
        axios
            .delete(
                `${process.env.REACT_APP_API_URL}/roles/remove/${id}`,
                config
            )
            .then((res) => {
                console.log(`Position with ID: ${id} successfully removed`);
            })
            .catch((err) => {
                console.log(err);
            });
        setReload(!reload);
    };

    return (
        <main className="main">
            <h1 className="heading-master">Create Roles</h1>
            <section className="positions" style={{ minHeight: "70vh" }}>
                <aside>
                    <h4>New Position</h4>
                    <div className="fields">
                        <label>
                            Name <span>*</span>
                        </label>
                        <input
                            type="text"
                            onChange={(e) => setName(e.target.value)}
                        />
                        <label>Description</label>
                        <textarea
                            cols={21}
                            rows={3}
                            onChange={(e) => setDescription(e.target.value)}
                        />
                    </div>
                    <Button
                        variant="contained"
                        color="success"
                        endIcon={<DataSaverOnIcon />}
                        id="position_btn"
                        onClick={addPosition}
                    >
                        Add
                    </Button>
                    {showAlert ? (
                        <Alert severity={severity} sx={{ marginBottom: "2%" }}>
                            {message}
                        </Alert>
                    ) : null}
                </aside>

                <section className="current__position">
                    <table className="position__table">
                        <thead>
                            <tr>
                                <th colSpan={2}>
                                    <h4>Current positions</h4>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {positions.map((position, i) => {
                                return (
                                    <tr key={i}>
                                        <td>{position.roleName}</td>
                                        <td>
                                            <Button
                                                sx={{ margin: "2px" }}
                                                variant="outlined"
                                                color="error"
                                                endIcon={<DeleteOutlineIcon />}
                                                onClick={() =>
                                                    deletePosition(
                                                        position.roleId
                                                    )
                                                }
                                            >
                                                Remove
                                            </Button>
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </section>
            </section>
        </main>
    );
}
