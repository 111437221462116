import { useState, useEffect } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import "./main.scss";
import Notifications from "./Notifications/Notifications";
import CompanyDash from "./CompanyDash";
import CircleNotificationsIcon from "@mui/icons-material/CircleNotifications";
import { Button } from "@mui/material";
import axios from "axios";
import { Modal } from "@mui/material";
import moment from "moment";
//Utils
import { getCompanies } from "../../utils/apiUtils";

export default function Home() {
    const navigate = useNavigate();
    const [user, setUser] = useState({});
    const [token, setToken] = useState("");
    const [sites, setSites] = useState([]);
    const [month, setMonth] = useState("");
    const [alert, setAlert] = useState(false);
    const [openNotifications, setOpenNotifications] = useState(false);
    const [amountOfNotifications, setAmountOfNotifications] = useState(0);

    useEffect(() => {
        let user = localStorage.getItem("user");
        let parseU = JSON.parse(user);
        setUser(user);
        if (!user) {
            return <Navigate to="/" />;
        }
        let localToken = parseU.token;
        setToken(localToken);

        getCompanies(localToken)
            .then((sites) => {
                let _sites = [];
                let _ignoreSites = [1, 14, 15];

                for (let i = 0; i < sites.length; i++) {
                    if (!_ignoreSites.includes(sites[i].companyId)) {
                        _sites.push(sites[i]);
                    }
                }
                setSites(_sites);
            })
            .catch((err) => {
                console.log(`Could not get sites: ${err}`);
                setAlert(true);
            });

        setMonth(moment().format("YYYY-MM"));

        axios
            .get(`${process.env.REACT_APP_API_URL}/notifications/active`)
            .then((res) => {
                setAmountOfNotifications(res.data.length);
            })
            .catch((err) => {
                console.log(err);
            });
    }, []);

    return (
        <>
            <section className="main">
                <Button
                    className={`notification-btn`}
                    variant="contained"
                    onClick={() => setOpenNotifications(true)}
                >
                    <CircleNotificationsIcon />
                    <p>{amountOfNotifications}</p>
                </Button>

                {!alert ? (
                    <section className="dashboard_main">
                        <section className="dashboard_search">
                            <label>Select a month</label>
                            <input
                                type="month"
                                onChange={(e) => setMonth(e.target.value)}
                                value={month}
                            />
                        </section>
                        <section className="dashboard_cards">
                            {sites.map(function (site, i) {
                                return (
                                    <CompanyDash
                                        key={i}
                                        site={site}
                                        month={month}
                                    />
                                );
                            })}
                        </section>
                    </section>
                ) : (
                    <h1 className="alert_sites">
                        Sorry, unable to get sites - Please refresh. If issue
                        persists contact IT
                    </h1>
                )}
            </section>
            <Modal open={openNotifications}>
                <Notifications setOpenNotifications={setOpenNotifications} />
            </Modal>
        </>
    );
}
