import { useState, useEffect } from "react";
import axios from "axios";
import { decryptData } from "../../employeeManagement/rotasV2/cryptoUtils";
import { getSitesData } from "../../../../utils/apiUtils";
//Styling
import "./holiday-reports.scss";
//MUI
import RefreshIcon from "@mui/icons-material/Refresh";
import { Button } from "@mui/material";
//Component
import HolidayReportsRow from "./HolidayReportsRow";
import Loader from "../../../animations/Loader";
import moment from "moment";

export default function HolidayReports() {
    //Header
    const url = process.env.REACT_APP_API_URL;
    // const url = "http://10.0.0.174:4030";
    //Holidays
    const [fixedHolidays, setFixedHolidays] = useState([]);
    const [displayedHolidays, setDisplayedHolidays] = useState([]);
    //Filters & Searches
    const [statuses, setStatues] = useState([]);
    const [selectedStatus, setSelectedStatus] = useState(0);
    const [sites, setSites] = useState([]);
    const [selectedSite, setSelectedSite] = useState(0);
    const [dateRangeStart, setDateRangeStart] = useState("");
    const [dateRangeEnd, setDateRangeEnd] = useState("");
    const [searchedName, setSearchedName] = useState("");
    //Loader
    const [loader, setLoader] = useState(false);

    useEffect(() => {
        setLoader(true);
        //Get and store Auth token
        let _token = JSON.parse(localStorage.getItem("user"));
        let config = {
            headers: {
                "Content-Type": "application/json",
                "x-auth-token": _token.token,
            },
        };

        //Bring in Holidays
        axios
            .get(`${url}/holiday/admin/all`, config)
            .then(async (res) => {
                let _holidays = res.data;

                let _newHolidays = await _holidays.map((holiday) => {
                    let _firstName = decryptData(
                        holiday.firstName,
                        process.env.REACT_APP_EMP_KEY
                    );
                    let _lastName = decryptData(
                        holiday.lastName,
                        process.env.REACT_APP_EMP_KEY
                    );
                    return {
                        ...holiday,
                        firstName: _firstName,
                        lastName: _lastName,
                    };
                });

                setFixedHolidays(_newHolidays);
                setDisplayedHolidays(_newHolidays);
                setLoader(false);
            })
            .catch((err) => {
                console.log(err);
                setLoader(false);
            });

        //Bring in Statuses
        axios
            .get(`${url}/holiday/statuses`, config)
            .then((res) => {
                setStatues(res.data);
            })
            .catch((err) => {
                console.log(err);
            });

        //Bring in Sites
        getSitesData()
            .then((res) => {
                setSites(res);
            })
            .catch((err) => {
                console.log(err);
            });
    }, []);

    const handleRefresh = () => {
        setSelectedSite(0);
        setSelectedStatus(0);
        setSearchedName("");
        setDateRangeStart("");
        setDateRangeEnd("");
        setDisplayedHolidays(fixedHolidays);
    };

    const handleFilters = (value, field) => {
       
        setSearchedName("");
        setDateRangeStart("");
        setDateRangeEnd("");

        let filterData = {
            siteId: field === "site" ? parseInt(value) : parseInt(selectedSite),
            statusId:
                field === "status" ? parseInt(value) : parseInt(selectedStatus),
        };

     

        //Remove any key/value pairs from the filtered data where the value is 0 (default)
        const removeZeros = (item) =>
            Object.keys(item)
                .filter((key) => item[key] !== 0)
                .reduce((newObj, key) => {
                    newObj[key] = item[key];
                    return newObj;
                }, {});
        const _result = removeZeros(filterData);

        //Create a new filter array of the key value pairs that have to be filtered
        let filteredArray = [];
        for (const [key, value] of Object.entries(_result)) {
            filteredArray.push({ key: key, value: value });
        }

        //Loop through filter array and set new array
        let finalFiltered = fixedHolidays;
        for (let index = 0; index < filteredArray.length; index++) {
            finalFiltered = finalFiltered.filter(
                (order) =>
                    order[filteredArray[index].key] ==
                    filteredArray[index].value
            );
        }

        //Set Current filter selects
        if (field === "status") {
            setSelectedStatus(value);
        } else if (field === "site") {
            setSelectedSite(value);
        }
        //Set new displayed requests
        setDisplayedHolidays(finalFiltered);
    };

    //Search by name
    const handleSearchEmployee = (name) => {
        handleRefresh();
        let lowerCaseName = name.toLowerCase();

        if (name === "") {
            setDisplayedHolidays(fixedHolidays);
        } else {
            let filteredHolidays = fixedHolidays.filter((holiday) => {
                let employeeFullName =
                    holiday.firstName.toLowerCase() +
                    " " +
                    holiday.lastName.toLowerCase();
                let lowerCaseEmployee = holiday.lastName.toLowerCase();
                let lowerCaseEmployeeFirst = holiday.firstName.toLowerCase();
                return (
                    lowerCaseEmployee.includes(lowerCaseName) ||
                    lowerCaseEmployeeFirst.includes(lowerCaseName) ||
                    employeeFullName.includes(lowerCaseName)
                );
            });

            setDisplayedHolidays(filteredHolidays);
        }
    };

    //Search by date ranges
    const handleDateSearch = (date, type) => {
        //Determine if date change is the start or end
        if (type === "start") {
            setDateRangeStart(date);
            //If end date set then do lookup
            if (dateRangeEnd !== "") {
                setSearchedName("");
                setSelectedStatus(0);
                setSelectedSite(0);
                let _filteredArray = fixedHolidays.filter((holiday) => {
                    let _formattedDate = moment(holiday.startDate).format(
                        "YYYY-MM-DD"
                    );
                    return (
                        _formattedDate >= date && _formattedDate <= dateRangeEnd
                    );
                });
                setDisplayedHolidays(_filteredArray);
            }
        } else {
            setDateRangeEnd(date);
            //If start dare set then do lookup
            if (dateRangeStart !== "") {
                setSearchedName("");
                setSelectedStatus(0);
                setSelectedSite(0);
                let _filteredArray = fixedHolidays.filter((holiday) => {
                    let _formattedDate = moment(holiday.startDate).format(
                        "YYYY-MM-DD"
                    );
                    return (
                        _formattedDate >= dateRangeStart &&
                        _formattedDate <= date
                    );
                });
                setDisplayedHolidays(_filteredArray);
            }
        }
    };

    return (
        <main className="holiday-reports main">
            <h1 className="heading-master">Holiday Reports</h1>
            <section className="filter-section">
                <Button
                    variant="contained"
                    color="warning"
                    className="refresh-btn"
                    onClick={handleRefresh}
                >
                    <RefreshIcon />
                </Button>
                <select
                    onChange={(e) => {
                        setSelectedSite(e.target.value);
                        handleFilters(e.target.value, "site");
                    }}
                    value={selectedSite}
                >
                    <option value={0}>Filter by Site</option>
                    {sites.length > 0
                        ? sites.map((site, i) => {
                              return (
                                  <option key={i} value={site.epos}>
                                      {site.name}
                                  </option>
                              );
                          })
                        : null}
                </select>
                <select
                    onChange={(e) => {
                        setSelectedStatus(e.target.value);
                        handleFilters(e.target.value, "status");
                    }}
                    value={selectedStatus}
                >
                    {" "}
                    <option value={0}>Filter by Status</option>
                    {statuses.length > 0
                        ? statuses.map((status, i) => {
                              return (
                                  <option key={i} value={status.statusId}>
                                      {status.name}
                                  </option>
                              );
                          })
                        : null}
                </select>
                <input
                    placeholder="Search by name"
                    onChange={(e) => {
                        handleSearchEmployee(e.target.value);
                        setSearchedName(e.target.value);
                    }}
                    value={searchedName}
                />
                <div className="date-filters">
                    <input
                        type="date"
                        onChange={(e) => {
                            if (e.target.value === "") {
                                setDisplayedHolidays(fixedHolidays);
                                setDateRangeStart("");
                            } else {
                                handleDateSearch(e.target.value, "start");
                            }
                        }}
                        value={dateRangeStart}
                        max={dateRangeEnd}
                    />{" "}
                    -
                    <input
                        type="date"
                        onChange={(e) => {
                            if (e.target.value === "") {
                                setDisplayedHolidays(fixedHolidays);
                                setDateRangeEnd("");
                            } else {
                                handleDateSearch(e.target.value, "end");
                            }
                        }}
                        value={dateRangeEnd}
                        min={dateRangeStart}
                    />
                </div>
            </section>
            <section className="holiday-table-container">
                {!loader ? (
                    displayedHolidays.length > 0 ? (
                        <table className="holiday-table">
                            <thead>
                                <tr>
                                    <th>Site</th>
                                    <th>Employee</th>
                                    <th>Start</th>
                                    <th>End</th>
                                    <th>Days</th>
                                    <th>Status</th>
                                    <th>Info</th>
                                </tr>
                            </thead>
                            <tbody>
                                {displayedHolidays.map((holiday, i) => {
                                    if (i <= 250) {
                                        return (
                                            <HolidayReportsRow
                                                key={i}
                                                holiday={holiday}
                                            />
                                        );
                                    }
                                })}
                            </tbody>
                        </table>
                    ) : (
                        <h2 className="no-holidays">No holidays</h2>
                    )
                ) : (
                    <Loader />
                )}
            </section>
        </main>
    );
}
