import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import { useState, useEffect } from "react";
import Alert from "@mui/material/Alert";
import InputLabel from "@mui/material/InputLabel";
import { getLetterTypes, getS3Link } from "../../../../utils/apiUtils";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import axios from "axios";
import Loader from "../../../animations/Loader";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "40vw",
  maxHeight: "35vh",
  bgcolor: "background.paper",
  border: "none",
  borderRadius: "1rem",
  boxShadow: 24,
  p: 4,
  overFlowY: "scroll",
};

export default function UploadDocs(props) {
  const [showAlert, setShowAlert] = useState(false);
  const [message, setMessage] = useState("");
  const [letterTypes, setLetterTypes] = useState([]);
  const [docType, setDocType] = useState(0);
  const [documentData, setDocument] = useState(null);
  const [token, setToken] = useState("");
  const [hrUser, setHrUser] = useState("")
  const config = { headers: { "x-auth-token": token } };
  const [showLoader, setShowLoader] = useState(false);

  useEffect(() => {
    let user = localStorage.getItem("user");
    let parseU = JSON.parse(user);
    let localToken = parseU.token;
    setToken(localToken);
    setHrUser(parseU.user)
    const localConfig = { headers: { "x-auth-token": localToken } };

    getLetterTypes(localConfig)
      .then((letters) => {
        console.log(letters);
        setLetterTypes(letters);
      })
      .catch((err) => {
        console.log(`could not get letters: ${err}`);
      });
  }, []);

  const handleDoc = (doc) => {
    let extension = doc.name.split(".").pop();
    if (extension === "exe") {
      setMessage(`Document extension not accepted`);
      setShowAlert(true);
      setTimeout(() => {
        setShowAlert(false);
      }, 3000);
    } else {
      setDocument(doc);
    }
  };

  const handleUpload = async () => {
    document.getElementById("submit_btn").style.display = "none";
    setShowLoader(true)
    const data = {
      type: docType,
      employeeDetailsId: props.employee,
      fileName: documentData.name,
      user: hrUser
    };
    await getS3Link(data, config)
      .then((res) => {
        uploadS3(res.url);
      })
      .catch((err) => {
        console.log(`Could not get the S3 link: ${err.response.data}`);
        setShowLoader(false)
        setMessage(`Could not upload the document, please try again`);
        document.getElementById("submit_btn").style.display = "flex";
        setShowAlert(true);
        setTimeout(() => {
          setShowAlert(false);
        }, 3500);
      });
  };

  const uploadS3 = (link) => {
    axios
      .put(link, documentData)
      .then((res) => {
        props.close();
        props.rel();
      })
      .catch((err) => {
        console.log(`Could not upload to S3: ${err.response.data}`);
        setShowLoader(false)
        setMessage(`Could not upload the document, please try again`);
        setShowAlert(true);
        setTimeout(() => {
          setShowAlert(false);
        }, 3500);
      });
  };

  return (
    <Box sx={style}>
      <Typography
        id="modal-modal-title"
        variant="h6"
        component="h2"
        sx={{ textAlign: "center" }}
      >
        <InputLabel>PLEASE SELECT A DOCUMENT</InputLabel>
        <input type="file" onChange={(e) => handleDoc(e.target.files[0])} />
      </Typography>
      <Typography
        id="modal-modal-title"
        variant="h6"
        component="h2"
        sx={{ textAlign: "center", margin: "2% auto" }}
      >
        <FormControl fullWidth>
          <InputLabel>DOCUMENT TYPE</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            label="Age"
            onChange={(e) => setDocType(e.target.value)}
          >
            {letterTypes.map((letter) => {
              return (
                <MenuItem value={letter.typeId} key={letter.typeId}>
                  {letter.name}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </Typography>
      {showLoader ? (
        <Loader />
      ) : (
        <>
          {showAlert ? (
            <Alert severity="warning">{message}</Alert>
          ) : (
            <Stack
              direction="row"
              spacing={12}
              alignItems="center"
              justifyContent="center"
            >
              <Button onClick={props.close} variant="outlined" color="error">
                Cancel
              </Button>
              <Button
                id="submit_btn"
                onClick={handleUpload}
                variant="contained"
                color="success"
              >
                Upload
              </Button>
            </Stack>
          )}
        </>
      )}
    </Box>
  );
}
