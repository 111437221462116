import { useState, useEffect } from "react";
import { Box, Button } from "@mui/material";
import { decryptData } from "../../../utils/cryptoUtils";
import axios from "axios";
import moment from "moment";

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 500,
    bgcolor: "background.paper",
    border: "none",
    borderRadius: "1rem",
    boxShadow: 24,
    p: 4,
};

export default function NoteModal({
    notification,
    setOpenModal,
    handleReloadNotifications,
}) {
    const [additionalNotificationInfo, setAdditionalNotificationInfo] =
        useState({});
    useEffect(() => {
        getNotificationsInfoById();
    }, []);

    const getNotificationsInfoById = () => {
        axios
            .get(
                `${process.env.REACT_APP_API_URL}/notifications/single/${notification.notificationId}`
            )
            .then((res) => {
                let notificationData = res.data;
                var firstName = decryptData(
                    notificationData.firstName,
                    process.env.REACT_APP_EMP_KEY
                );
                var lastName = decryptData(
                    notificationData.lastName,
                    process.env.REACT_APP_EMP_KEY
                );
                notificationData.fullName = `${firstName} ${lastName}`;
                setAdditionalNotificationInfo(notificationData);
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const handleMarkAsRead = () => {
        axios
            .post(`${process.env.REACT_APP_API_URL}/notifications/confirmed`, {
                id: notification.notificationId,
            })
            .then((res) => {
                if (res.data.updated) {
                    handleReloadNotifications();
                    setOpenModal(false);
                }
            })
            .catch((err) => {
                console.error("Error marking as read:", err);
            });
    };

    return (
        <Box sx={style}>
            <h1 style={{ textAlign: "center" }}>
                Notification ID: {notification.notificationId}
            </h1>
            {additionalNotificationInfo.notificationId ? (
                <>
                    <p>
                        <b>Staff:</b> {additionalNotificationInfo.fullName}
                    </p>
                    <p>
                        <b>Shift Id:</b> {additionalNotificationInfo.shiftId}
                    </p>
                    <p>
                        <b>Shift Date:</b>{" "}
                        {moment(additionalNotificationInfo.startTime).format(
                            "DD/MM/YYYY"
                        )}
                    </p>
                    <p>
                        <b>Edit Made:</b>{" "}
                        {additionalNotificationInfo.editReason}
                    </p>
                    <p>
                        <b>Edited By:</b> {additionalNotificationInfo.user}
                    </p>
                    <p>
                        <b>Edited On:</b>{" "}
                        {moment(additionalNotificationInfo.timeOfEdit).format(
                            "HH:mm - DD/MM/YYYY"
                        )}
                    </p>
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "space-around",
                        }}
                    >
                        <Button
                            variant="contained"
                            color="error"
                            onClick={() => setOpenModal(false)}
                        >
                            Close
                        </Button>
                        <Button
                            variant="contained"
                            color="success"
                            onClick={handleMarkAsRead}
                        >
                            Mark As Read
                        </Button>
                    </div>
                </>
            ) : null}
        </Box>
    );
}
