import Box from "@mui/material/Box";
import { Button } from "@mui/material";
import moment from "moment";
import { useState } from "react";

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 500,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
};
export default function OvertimeInfo({ setOpenInfo, approval }) {
    const [ogShift, setOgShift] = useState(approval.originalShift);
    return (
        <Box sx={style} className="info-overtime-modal">
            <p className="info-id">Shift Id: {approval.shiftId}</p>
            <h2>Overtime Info</h2>
            <table className="info-overtime-table">
                <tbody>
                    <tr>
                        <th>Original Start</th>
                        <td>
                            {moment(ogShift.startTime).format("HH:mm DD/MM/YY")}
                        </td>
                    </tr>
                    <tr>
                        <th>Original End</th>
                        <td>
                            {moment(ogShift.endTime).format("HH:mm DD/MM/YY")}
                        </td>
                    </tr>
                    <tr>
                        <th>Original Hours</th>
                        <td>{ogShift.hoursWorked}</td>
                    </tr>
                    <tr>
                        <th>Original Break</th>
                        <td>{ogShift.breakTime}</td>
                    </tr>
                    <tr>
                        <th>Store Approver</th>
                        <td>{approval.user}</td>
                    </tr>
                    <tr>
                        <th>Store Approved</th>
                        <td>{approval.wasApproved}</td>
                    </tr>
                    <tr>
                        <th>Note</th>
                        <td>{approval.note ? approval.note : "No note"}</td>
                    </tr>
                </tbody>
            </table>
            <Button
                variant="contained"
                color="error"
                onClick={() => setOpenInfo(false)}
            >
                Close
            </Button>
        </Box>
    );
}
