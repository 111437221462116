import React from "react";
import { useNavigate, Outlet } from "react-router-dom";
import "./admin.scss";
import ErrorBoundary from "../../../utils/ErrorBoundary";

export default function AdminTasks() {
    const navigate = useNavigate();

    return (
        <main className="main">
            <section className="home">
                <div
                    className="card"
                    onClick={() => navigate("/admin/update-company")}
                >
                    <h1>Update Company</h1>
                </div>
                <div
                    className="card"
                    onClick={() => navigate("/admin/create-company")}
                >
                    <h1>Create Company</h1>
                </div>
                <div
                    className="card"
                    onClick={() => navigate("/admin/pay-rates")}
                >
                    <h1>Pay Rates</h1>
                </div>
                <div className="card" onClick={() => navigate("/admin/roles")}>
                    <h1>Create Roles</h1>
                </div>
                <div
                    className="card"
                    onClick={() => navigate("/admin/holiday-groups")}
                >
                    <h1>Holiday Groups</h1>
                </div>

                <ErrorBoundary>
                    <Outlet />
                </ErrorBoundary>
            </section>
        </main>
    );
}
