import { useState, useEffect } from "react";
import axios from "axios";
import "./admin.scss";
import moment from "moment";
import Button from "@mui/material/Button";
import Alert from "@mui/material/Alert";

export default function Scripts() {
    const weeklyStart = moment().isoWeekday(-6).format("DD/MM/YYYY");
    const weeklyEnd = moment(weeklyStart, "DD/MM/YYYY")
        .add(6, "days")
        .format("DD/MM/YYYY");

    const [buttonsDisabled, setButtonsDisabled] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [alertsMsg, setAlertMsg] = useState("");
    const [severity, setSeverity] = useState("");

    const [token, setToken] = useState(false);

    useEffect(() => {
        let checkId = localStorage.getItem("user");
        let parsed = JSON.parse(checkId);
        let token = parsed.token;
        setToken(token);
    }, []);

    const config = {
        headers: { "Content-Type": "application/json", "x-auth-token": token },
    };

    const handleScript = (eventId) => {
        setButtonsDisabled(true);

        axios
            .post(
                `${process.env.REACT_APP_API_URL}/webhook/cronscript/${eventId}`,
                config
            )
            .then((res) => {
                console.log(res.data);
                setAlertMsg("Script has ran Successfuly");
                setSeverity("success");
                setShowAlert(true);

                setTimeout(() => {
                    setShowAlert(false);
                    setAlertMsg("");
                }, 2500);
            })
            .catch((err) => {
                setAlertMsg("Somethings went wrong");
                setSeverity("error");
                setShowAlert(true);

                setTimeout(() => {
                    setShowAlert(false);
                    setAlertMsg("");
                }, 2500);
            })
            .finally(() => {
                setButtonsDisabled(false);
            });
    };

    return (
        <main className="main">
            <div className="scripts">
                <h1 className="heading-master">Manually Run Scripts</h1>
                {showAlert ? (
                    <Alert className="alert-header" severity={severity}>
                        {alertsMsg}
                    </Alert>
                ) : (
                    <></>
                )}
                <table>
                    <thead>
                        <th>Script</th>
                        <th>Description</th>
                        <th>Run</th>
                    </thead>
                    <tbody>
                        <tr>
                            <td>Starters / Leavers (Monthly)</td>
                            <td>
                                Receive all Starters and Leavers for this month
                                ({moment().format("MMMM")}) via Email.
                            </td>
                            <td>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    sx={{ mt: 2 }}
                                    onClick={() => handleScript("em1f0lx0jjk")}
                                    disabled={buttonsDisabled}
                                >
                                    Run Script
                                </Button>
                            </td>
                        </tr>
                        <tr>
                            <td>Starters / Leavers (Weekly)</td>
                            <td>
                                Receive all Starters and Leavers for the current
                                week ({weeklyStart}-{weeklyEnd}) via Email.
                            </td>
                            <td>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    sx={{ mt: 2 }}
                                    onClick={() => handleScript("em1f0by0uji")}
                                    disabled={buttonsDisabled}
                                >
                                    Run Script
                                </Button>
                            </td>
                        </tr>
                        <tr>
                            <td>Payroll (Monthly)</td>
                            <td>
                                Receive Payroll Information for last month (
                                {moment()
                                    .subtract(1, "month")
                                    .startOf("month")
                                    .format("MMMM")}
                                ) via Email.
                            </td>
                            <td>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    sx={{ mt: 2 }}
                                    onClick={() => handleScript("elpiagpfo08")}
                                    disabled={buttonsDisabled}
                                >
                                    Run Script
                                </Button>
                            </td>
                        </tr>
                        <tr>
                            <td>Payroll (Current Month)</td>
                            <td>
                                Receive Payroll Information for the current
                                month (
                                {moment().startOf("month").format("DD/MM/YYYY")}
                                -{moment().endOf("month").format("DD/MM/YYYY")})
                                via Email. This will contain all available
                                weeks.
                            </td>
                            <td>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    sx={{ mt: 2 }}
                                    onClick={() => handleScript("elpiaflhj07")}
                                    disabled={buttonsDisabled}
                                >
                                    Run Script
                                </Button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </main>
    );
}
