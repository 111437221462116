import axios from "axios";

const hrUrl = process.env.REACT_APP_API_URL;

const url = "https://nitropos-backend.nitrosolutions.co.uk";

let token = localStorage.getItem("user");
let getToken, final;

if (token === null) {
  console.log(window.location.origin);
  // if (window.location.origin === "https://hr.nitrosolutions.co.uk/")
  // if (document.getElementById("login_btn")){
  final = "";
  // }else {
  // let localToken = localStorage.getItem("user");
  // let getLocalToken = JSON.parse(localToken)
  // final = getLocalToken.token
  // }
} else {
  getToken = JSON.parse(token);
  final = getToken.token;
}

const config = {
  headers: { "Content-Type": "application/json", "x-auth-token": final },
};

const authenticatePassword = async (password) => {
  const username = JSON.parse(localStorage.getItem("userName"));

  try {
    const response = await axios.post(process.env.REACT_APP_LOGIN_ENDPOINT, {
      username: username,
      password: password,
    });

    if (response.data) {
      return true;
    } else {
      console.error("Password authentication failed.");
      return false;
    }
  } catch (error) {
    console.error("Error authenticating password:", error);
    throw error;
  }
};

const getCompanies = (token) => {
  let localConfig;
  if (token) {
    localConfig = {
      headers: { "Content-Type": "application/json", "x-auth-token": token },
    };
  } else {
    localConfig = config;
  }
  return axios
    .get(`${hrUrl}/company`, localConfig)
    .then((res) => res.data)
    .catch((err) => {
      console.error(`Could not get companies: ${err.response.data}`);
      return [];
    });
};

const getAllSites = () => {
  return axios
    .get(`${url}/eposSQL/getAllSites`, config)
    .then((res) => res.data)
    .catch((err) => {
      console.error(err);
      return [];
    });
};

const updateCompany = (updatedCompany, token) => {
  let localConfig = {
    headers: { "Content-Type": "application/json", "x-auth-token": token },
  };

  return axios
    .post(`${hrUrl}/company/update`, updatedCompany, localConfig)
    .then((res) => res.data)
    .catch((error) => {
      console.error("Error updating company:", error);
      throw error;
    });
};

const createCompany = (newCompany, token) => {
  let localConfig = {
    headers: { "Content-Type": "application/json", "x-auth-token": token },
  };
  return axios
    .post(`${hrUrl}/company/add`, newCompany, localConfig)
    .then((res) => res.data)
    .catch((error) => {
      console.error("Error creating company:", error);
      throw error;
    });
};

const deleteCompany = async (companyId, token) => {
  let localConfig = {
    headers: { "Content-Type": "application/json", "x-auth-token": token },
  };

  try {
    const response = await axios.delete(
      `${hrUrl}/company/remove/${companyId}`,
      localConfig
    );

    if (response.status === 200) {
      return true;
    } else {
      throw new Error("Failed to delete company");
    }
  } catch (error) {
    console.error("Error deleting company:", error);
    throw error;
  }
};

const getPositions = (token) => {
  let localConfig;

  if (token) {
    localConfig = {
      headers: { "Content-Type": "application/json", "x-auth-token": token },
    };
  } else {
    localConfig = config;
  }

  return axios
    .get(`${hrUrl}/roles`, localConfig)
    .then((res) => res.data)
    .catch((err) => {
      console.error(err);
      return [];
    });
};

const getEmployeesByCompany = (companyId, token) => {
  let localConfig;
  if (token) {
    localConfig = {
      headers: { "Content-Type": "application/json", "x-auth-token": token },
    };
  } else {
    localConfig = config;
  }

  return axios
    .get(`${hrUrl}/employee/companyId/${companyId}`, localConfig)
    .then((res) => res.data)
    .catch((err) => {
      console.error(err);
      return [];
    });
};

const getAllEmployeesByCompany = (companyId, token) => {
  let localConfig;
  if (token) {
    localConfig = {
      headers: { "Content-Type": "application/json", "x-auth-token": token },
    };
  } else {
    localConfig = config;
  }

  return axios
    .get(`${hrUrl}/employee/companyId/${companyId}/all`, localConfig)
    .then((res) => res.data)
    .catch((err) => {
      console.error(err);
      return [];
    });
};

const getArchivedEmployeesByCompany = (companyId, token) => {
  let localConfig;
  if (token) {
    localConfig = {
      headers: { "Content-Type": "application/json", "x-auth-token": token },
    };
  } else {
    localConfig = config;
  }

  return axios
    .get(`${hrUrl}/employee/companyId/${companyId}/archived`, localConfig)
    .then((res) => res.data)
    .catch((err) => {
      console.error(err);
      return [];
    });
};

const getEmployeeDetailsById = async (employeeId, token) => {
  let localConfig;

  if (token) {
    localConfig = {
      headers: { "Content-Type": "application/json", "x-auth-token": token },
    };
  } else {
    localConfig = config;
  }

  try {
    const response = await axios.get(
      `${hrUrl}/employee/single/${employeeId}?documents=true`,
      localConfig
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching employee details:", error);
    throw error;
  }
};

const updateEmployee = async (employeeId, encryptedData, token, user) => {
  let localConfig;
  if (token) {
    localConfig = {
      headers: { "Content-Type": "application/json", "x-auth-token": token },
    };
  } else {
    localConfig = config;
  }

  try {
    const updatedEmployeeData = {
      ...encryptedData,
      employeeDetailsId: employeeId,
      user: user
    };

    const response = await axios.post(
      `${hrUrl}/employee/update`,
      updatedEmployeeData,
      localConfig
    );

    if (response.status === 200) {
      return true;
    } else {
      throw new Error("Failed to update employee");
    }
  } catch (error) {
    console.error("Error updating employee:", error);
    throw error;
  }
};

const deleteEmployee = async (employeeId, token, hrUser, reason) => {
  const config = {
    headers: { "Content-Type": "application/json", "x-auth-token": token },
  };
  try {
    const response = await axios.post(
      `${hrUrl}/employee/remove/${employeeId}`, { user: hrUser, reason: reason },
      config
    );

    if (response.status === 200) {
      return true;
    } else {
      throw new Error("Failed to delete employee");
    }
  } catch (error) {
    console.error("Error deleting employee:", error);
    throw error;
  }
};

const fetchAllInterviews = async (token) => {
  let localConfig;

  if (token) {
    localConfig = {
      headers: { "Content-Type": "application/json", "x-auth-token": token },
    };
  } else {
    localConfig = config;
  }

  try {
    const response = await axios.get(`${hrUrl}/interview/all`, localConfig);
    // console.log(response.data)
    return response.data;
  } catch (error) {
    console.error("Error fetching interview data:", error);
    throw error;
  }
};

const getInterviewDetails = (id, token) => {
  let localConfig;

  if (token) {
    localConfig = {
      headers: { "Content-Type": "application/json", "x-auth-token": token },
    };
  } else {
    localConfig = config;
  }

  return axios
    .get(`${hrUrl}/interview/${id}`, localConfig)
    .then((res) => res.data)
    .catch((err) => {
      console.error(err);
      return null;
    });
};

const submitInterview = async (interviewData, cv, configForm) => {
  console.log(interviewData);
  const formData = new FormData();
  formData.append("file", cv);
  formData.append("interview", JSON.stringify(interviewData));

  try {
    const response = await axios.post(
      `${hrUrl}/interview/new`,
      formData,
      configForm
    );
    if (response.status === 200) {
      return true;
    }
  } catch (error) {
    console.error("Error submitting the interview:", error);
    throw error;
  }
};

const updateInterview = async (encryptedData, token) => {
  let localConfig;
  if (token) {
    localConfig = {
      headers: { "Content-Type": "application/json", "x-auth-token": token },
    };
  } else {
    localConfig = config;
  }

  try {
    const response = await axios.post(
      `${hrUrl}/interview/update`,
      encryptedData,
      localConfig
    );

    if (response.status == 200) {
      return true;
    }
  } catch (error) {
    console.error("Error updating the interview:", error);
    throw error;
  }
};

const getAwaitingData = async (token) => {
  let localConfig;

  if (token) {
    localConfig = {
      headers: { "Content-Type": "application/json", "x-auth-token": token },
    };
  } else {
    localConfig = config;
  }
  try {
    const response = await axios.get(
      `${hrUrl}/onBoard/getAwaiting`,
      localConfig
    );
    // console.log(response.data)
    return response.data;
  } catch (error) {
    console.error("Error fetching awaiting data:", error);
    return [];
  }
};

const getSitesData = async () => {
  try {
    const response = await axios.get(
      "https://nitrous.rt-itservices.co.uk/sites/data",
      config
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching sites data:", error);
    return [];
  }
};

const getPayRates = async (token) => {
  let localConfig;

  if (token) {
    localConfig = {
      headers: { "Content-Type": "application/json", "x-auth-token": token },
    };
  } else {
    localConfig = config;
  }

  try {
    const response = await axios.get(`${hrUrl}/rates`, localConfig);
    return response.data;
  } catch (err) {
    console.error("Error getting pay rates data:", err);
    return [];
  }
};

const getVisaTypes = async (token) => {
  let localConfig;

  if (token) {
    localConfig = {
      headers: { "Content-Type": "application/json", "x-auth-token": token },
    };
  } else {
    localConfig = config;
  }

  try {
    const response = await axios.get(`${hrUrl}/visa/types`, localConfig);
    return response.data;
  } catch (err) {
    console.error("Error getting visa type data:", err);
    return [];
  }
};

const deleteInterview = (applicantId, token) => {
  const localConfig = {
    headers: { "Content-Type": "application/json", "x-auth-token": token },
  };

  try {
    const response = axios.post(
      `
      ${hrUrl}/interview/remove`,
      { applicantId }, localConfig
    );

    return response;
  } catch (error) {
    throw error;
  }
};

const getLeaveTypes = (token) => {
  return axios
    .get(`${process.env.REACT_APP_API_URL}/holiday/leaveTypes/new`, token)
    .then((res) => res.data)
    .catch((err) => {
      console.log(`Could not get leave types: ${err}`);
      return [];
    });
};

const chargeDiscrepancies = (discrepancies) => {
  try {
    const response = axios.post(`${url}/discrepancies/charge`, discrepancies)
    return response
  } catch (err) {
    throw (err);
  }
}

const getS3Link = (data, config) => {
  return axios
    .post(`${hrUrl}/employee/docupload-linkgenerator`, data, config)
    .then((res) => res.data)
    .catch((err) => {
      console.log(`Could not get the link from S3: ${err}`)
      return [];
    })
}

const getLetterTypes = (config) => {
  return axios
    .get(`${hrUrl}/employee/letterTypes`, config)
    .then((res) => res.data)
    .catch((err) => {
      console.log(`Could not get letter types: ${err}`)
      return [];
    })
}


export {
  getCompanies,
  getAllSites,
  updateCompany,
  createCompany,
  getPositions,
  fetchAllInterviews,
  getInterviewDetails,
  getEmployeesByCompany,
  getAllEmployeesByCompany,
  getArchivedEmployeesByCompany,
  submitInterview,
  updateInterview,
  getAwaitingData,
  getSitesData,
  getEmployeeDetailsById,
  authenticatePassword,
  getPayRates,
  getVisaTypes,
  deleteInterview,
  deleteCompany,
  deleteEmployee,
  updateEmployee,
  chargeDiscrepancies,
  getLeaveTypes,
  getS3Link,
  getLetterTypes
  // test
};
