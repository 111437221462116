import Box from "@mui/material/Box";
import { Button } from "@mui/material";
import axios from "axios";
import { useState } from "react";
import { Alert } from "@mui/material";

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
};

export default function ApproveOvertime({
    setOpenApprove,
    setReload,
    reload,
    id,
}) {
    //Alert
    const [alert, setAlert] = useState(false);
    const [disable, setDisable] = useState(false);
    const [message, setMessage] = useState("");
    const [severity, setSeverity] = useState("");

    const handleAlert = (message, severity, closeModal) => {
        setMessage(message);
        setSeverity(severity);
        setAlert(true);
        setTimeout(() => {
            setDisable(false);
            setAlert(false);
            setMessage("");
            setSeverity("");
            if (closeModal) {
                setOpenApprove(false);
                setReload(!reload);
            }
        }, 4000);
    };

    const handleApproveOvertime = async () => {
        setDisable(true);
        axios
            .post(`${process.env.REACT_APP_API_URL}/notifications/overtime/update`, { id:id, status:true })
            .then(async (res) => {
                handleAlert("Successfully Approved", "success", true);
            })
            .catch((err) => {
                console.log(err);
                handleAlert("Did not submit - try again", "error", false);
            });
    };

    return (
        <Box sx={style} className="approve-overtime-modal">
            <h2>Approve Overtime</h2>
            <p>Are you sure you wish to approve this overtime?</p>
            <section className="alert-section">
                {alert ? <Alert severity={severity}>{message}</Alert> : null}
            </section>
            <section className="btn-section">
                <Button
                    variant="contained"
                    color="success"
                    onClick={handleApproveOvertime}
                    disabled={disable}
                >
                    Approve
                </Button>
                <Button
                    variant="contained"
                    color="error"
                    onClick={() => setOpenApprove(false)}
                    disabled={disable}
                >
                    Close
                </Button>
            </section>
        </Box>
    );
}
