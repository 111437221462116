//React
import { useEffect, useState } from "react";
import axios from "axios";
//MUI
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Button, Alert } from "@mui/material";

//Styling
const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "30vw",
    bgcolor: "background.paper",
    border: "none",
    borderRadius: "1rem",
    boxShadow: 24,
    p: 4,
};

export default function ResetRota({
    handleClose,
    staffList,
    user,
    rotaId,
    reloadData,
}) {
    const url = process.env.REACT_APP_API_URL;
    const config = {
        headers: {
            "Content-Type": "application/json",
            "x-auth-token": localStorage.getItem("auth-token"),
        },
    };
    const [shiftsToDelete, setShiftsToDelete] = useState([]);
    const [alert, setAlert] = useState(false);
    const [message, setMessage] = useState("");
    const [severity, setSeverity] = useState("");

    useEffect(() => {
        //Strips out all shifts from employee list and stores in arr
        let _shiftsArr = staffList.map((d, i) => {
            if (d.worked.length > 0) {
                return d.worked;
            } else return [];
        });
        let _flatShiftsArr = _shiftsArr.flat();

        let _shiftsMinusLeave = _flatShiftsArr.filter((d, i) => {
            return d.shiftId;
        });
        setShiftsToDelete(_shiftsMinusLeave);
    }, []);

    const handleResetRota = () => {
        for (let i = 0; i < shiftsToDelete.length; i++) {
            const data = {
                shiftId: shiftsToDelete[i].shiftId,
                user: user,
            };

            axios
                .post(`${url}/rota/shift/delete`, data, config)
                .then((response) => {
                    handleClose();
                    reloadData();
                })
                .catch((error) => {
                    console.log(error);
                    setMessage("Unable to delete shift - Please try again");
                    setSeverity("error");
                    setAlert(true);
                    setTimeout(() => {
                        setAlert(false);
                        setMessage("");
                        setSeverity("");
                    }, 4000);
                });
        }
    };

    return (
        <Box sx={style}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
                Reset Current Rota
            </Typography>

            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                You are about to reset the currently selected rota (Rota ID:{" "}
                <b> {rotaId}</b>). This will revert all set shift back to empty
                shift. Are you sure you want to do this?
            </Typography>
            <section style={{ height: "8vh" }}>
                {alert ? <Alert severity={severity}>{message}</Alert> : null}
            </section>
            <section
                style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginTop: "1vh",
                    gap: "1vw",
                }}
            >
                <Button
                    onClick={() => {
                        handleClose(false);
                    }}
                    variant="contained"
                    color="error"
                    style={{ width: "100%" }}
                >
                    Close
                </Button>
                <Button
                    onClick={handleResetRota}
                    variant="contained"
                    style={{ width: "100%" }}
                >
                    Confirm Reset
                </Button>
            </section>
        </Box>
    );
}
