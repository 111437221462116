import React from "react";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";

const LogoutButton = ({ setLoggedIn }) => {
    const navigate = useNavigate();

    const handleLogout = () => {
        localStorage.clear();
        setLoggedIn(false);
        navigate("/");
    };

    return (
        <Button
            onClick={handleLogout}
            sx={{ color: "#2c53a0", fontSize: "1.1rem" }}
        >
            Logout
        </Button>
    );
};

export default LogoutButton;
